<template>
  <div>
    <PartouDataTable
      ref="partou-data-table"
      :headers="headers"
      :items="items"
      :totalCount="totalCount"
      :showExpand="false"
      :tableOptions="tableOptions"
      :isLoading="isLoading"
      :allowDelete="true"
      :allowEdit="true"
      @onOptionsChanged="onTableOptionsChangedAsync">
      <template #item.isEnabled="{ item }"> <!-- eslint-disable-line -->
          <PartouCheckBox v-model="item.isEnabled" :disabled="isLoading || !isAuthorized" @input="onFeatureChanged(item)"/>
      </template>
      <template #item.isMainFeature="{ item }"> <!-- eslint-disable-line -->
          <PartouCheckBox v-model="item.isMainFeature" :disabled="!item.isEnabled || isLoading || !isAuthorized" @input="onFeatureChanged(item)"/>
      </template>

      <template #top>
        <div class="col-3 filters mb-0 pb-0" v-if="tableOptions">
          <PartouTextField
            v-model="tableOptions.filters.search"
            @input="onSearchChanged"
            :iconName="'$vuetify.icons.partouSearch'"
            :label="$t('table.searchLabel')"
            class="mx-4"
            :iconPosition="'right'"
            size="small"
            :isClearable="true">
          </PartouTextField>
        </div>
      </template>
    </PartouDataTable>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Ref } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import BaseEntityTable from '@/pages/_base/BaseEntityTable'
import PartouDataTable from '@/components/PartouComponents/PartouDataTable/PartouDataTable.vue'
import PartouCheckBox from '@/components/PartouComponents/PartouCheckBox/PartouCheckBox.vue'
import { DataTableHeader } from '@/components/PartouComponents/PartouDataTable'
import { Feature } from '@/models'
import { ServiceFeatureTableOptions } from './ServiceFeatureTableOptions'
import { ServiceFeatureTableItem } from './ServiceFeatureTableItem'
import PartouTextField from '@/components/PartouComponents/PartouTextField.vue'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import Permission from '@/models/enums/Permission'
import { IAuthService } from '@/services/AuthService/IAuthService'

@Component({
  components: { PartouCard, PartouCheckBox, PartouDataTable, PartouTextField }
})
export default class ServiceFeatureTable extends BaseEntityTable<Feature> {
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  headers: DataTableHeader[] = [
    { text: 'services.features.table.columns.enabled', sortable: false, value: 'isEnabled', width: '200px' }, // servicefeature
    { text: 'services.features.table.columns.mainFeature', sortable: false, value: 'isMainFeature', width: '200px' }, // servicefeature
    { text: 'services.features.table.columns.description', sortable: true, value: 'description' }, // feature
    { text: 'services.features.table.columns.persona', sortable: false, value: 'personas', width: '250px' } // feature
  ]

  @Prop({ required: true })
  items!: Partial<ServiceFeatureTableItem>[]

  @Ref('partou-data-table')
  partouDataTable!: PartouDataTable<Feature>

  tableOptions: ServiceFeatureTableOptions = {
    sortBy: [],
    sortDesc: [],
    page: 1,
    itemsPerPage: 10,
    itemsPerPageOptions: [5, 10, 25, 50, 100],
    filters: {
      search: ''
    }
  }

  @Emit('onFeatureChanged')
  onFeatureChanged (item : ServiceFeatureTableItem) : ServiceFeatureTableItem {
    return item
  }

  onSearchChanged () : void {
    this.partouDataTable.onFilterChanged()
  }

  get isAuthorized (): boolean {
    return this.authService.hasPermission([Permission.ManageService])
  }
}
</script>
