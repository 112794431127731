<template>
  <div ref="waitinglist-allocation-result-filter" id="waitinglist-allocation-result-filter" class="full-width">
    <PartouCard id="waitingListAnalysisHeader" class="mb-4">
      <template slot="card-body">
        <div class="pl-6">
          <v-row>
            <v-col>
              <h2>{{ $t('waitingListPropositionAnalysis.filter.allocatedResultsOnly') }}</h2>
              <PartouCheckBox v-model="resultFilter.onlyAllocatedResults" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <h2>{{ $t('waitingListPropositionAnalysis.filter.from') }}</h2>
              <DateInputField class="date" label="startdatum" :allowWeekends="true" :use-single-line="false"
                v-model="resultFilter.fromDate" :maxDate="resultFilter.untilDate ? resultFilter.untilDate : maxDate"
                :ignoreInitDateChange="true" :isClearable="true"/>
            </v-col>
            <v-col cols="2">
              <h2>{{ $t('waitingListPropositionAnalysis.filter.until') }}</h2>
              <DateInputField class="date" label="einddatum" :allowWeekends="true" :use-single-line="false"
                v-model="resultFilter.untilDate" :maxDate="maxDate" :minDate="resultFilter.fromDate"
                :ignoreInitDateChange="true" @input="onUntilDateChanged" :isClearable="true"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="onFilterClicked">
                {{ $t('waitingListPropositionAnalysis.filter.search') }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
    </PartouCard>
  </div>
</template>

<script lang="ts">
import DateInputField from '@/components/InputFields/DateInputField/DateInputField.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import PartouCheckBox from '@/components/PartouComponents/PartouCheckBox/PartouCheckBox.vue'
import moment from 'moment'
import { Vue, Component, VModel, Emit } from 'vue-property-decorator'

export class WaitingListPropositionAnalysisResultFilters {
  fromDate?: Date
  untilDate?: Date
  onlyAllocatedResults?: boolean
}

@Component({
  components: { PartouCard, DateInputField, PartouCheckBox }
})
export default class WaitingListPropositionAnalysisPageFilter extends Vue {
  @VModel({ required: true })
  resultFilter!: WaitingListPropositionAnalysisResultFilters

  get maxDate (): Date {
    return new Date()
  }

  onUntilDateChanged (newUntilDate?: Date): void {
    if (newUntilDate) {
      // datePicker returns start of date, which'll exclude all calculations made on the day itself
      this.resultFilter.untilDate = new Date(`${moment(newUntilDate).format('YYYY-MM-DD')}T23:59:59`)
    }
  }

  @Emit('onFilterClicked')
  onFilterClicked (): WaitingListPropositionAnalysisResultFilters {
    return this.resultFilter
  }
}
</script>
