import { DeleteServicePricingByIdDocument, DeleteServicePricingByIdMutation, DeleteServicePricingByIdMutationVariables, GetDefaultSubscriptionsDocument, GetDefaultSubscriptionsQuery, GetServicePricingByServiceIdDocument, GetServicePricingByServiceIdQuery, GetServicePricingByServiceIdQueryVariables, GetSubscriptionsWithPricingsByServiceIdDocument, GetSubscriptionsWithPricingsByServiceIdQuery, GetSubscriptionsWithPricingsByServiceIdQueryVariables, InsertServicePricingDocument, InsertServicePricingMutation, InsertServicePricingMutationVariables, InsertSubscriptionServiceDocument, InsertSubscriptionServiceMutation, InsertSubscriptionServiceMutationVariables, ServicePricing, Subscription, UpdateServicePricingDocument, UpdateServicePricingMutation, UpdateServicePricingMutationVariables, UpdateSubscriptionServiceDocument, UpdateSubscriptionServiceMutation, UpdateSubscriptionServiceMutationVariables, SubscriptionService as SubscriptionServiceModel } from '@/models'
import { ApolloClient } from 'apollo-client'
import { inject, injectable } from 'inversify'
import { ISubscriptionService } from './ISubscriptionService'
import Permission from '@/models/enums/Permission'

@injectable()
export default class SubscriptionService implements ISubscriptionService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async getDefaultSubscriptionsAsync () : Promise<Subscription[]> {
    const result = await this.apollo.query<GetDefaultSubscriptionsQuery>({
      query: GetDefaultSubscriptionsDocument,
      context: { headers: { 'X-Hasura-Role': Permission.GetSetting } }
    })
    return result.data.subscription as Subscription[]
  }

  async getSubscriptionWithPricingsByServiceIdAsync (variables : GetSubscriptionsWithPricingsByServiceIdQueryVariables): Promise<SubscriptionServiceModel[]> {
    const result = await this.apollo.query<GetSubscriptionsWithPricingsByServiceIdQuery>({
      query: GetSubscriptionsWithPricingsByServiceIdDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetSetting } }
    })
    return result.data.subscription_service as SubscriptionServiceModel[] // eslint-disable-line
  }

  async upsertSubscriptionServiceAsync (subscriptionService : Partial<SubscriptionServiceModel>) : Promise<SubscriptionServiceModel | undefined> {
    let { id, subscriptionId, serviceId, isAvailable, isDisplayNote, note } = subscriptionService
    isAvailable = isAvailable ?? false
    isDisplayNote = (isDisplayNote && !!note && note.length > 0) ?? false
    if (id) {
      await this.updateSubscriptionServiceAsync({ id, isAvailable, isDisplayNote, note })
    } else if (subscriptionId && serviceId) {
      return await this.insertSubscriptionServiceAsync({ subscriptionId, serviceId, isAvailable, isDisplayNote, note })
    }
    return undefined
  }

  async insertSubscriptionServiceAsync (variables : InsertSubscriptionServiceMutationVariables) : Promise<SubscriptionServiceModel> {
    const result = await this.apollo.mutate<InsertSubscriptionServiceMutation>({
      mutation: InsertSubscriptionServiceDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.ManagePricing } }
    })
    return result.data?.insert_subscription_service_one as SubscriptionServiceModel // eslint-disable-line
  }

  async updateSubscriptionServiceAsync (variables : UpdateSubscriptionServiceMutationVariables) : Promise<void> {
    await this.apollo.mutate<UpdateSubscriptionServiceMutation>({
      mutation: UpdateSubscriptionServiceDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.ManagePricing } }
    })
  }

  async getSubscriptionPicingBySubscriptionServiceIdAsync (variables : GetServicePricingByServiceIdQueryVariables) : Promise<ServicePricing[]> {
    const result = await this.apollo.query<GetServicePricingByServiceIdQuery>({
      query: GetServicePricingByServiceIdDocument,
      variables
    })
    return result.data.service_pricing as ServicePricing[]
  }

  async insertSubscriptionPricingAsync (variables : InsertServicePricingMutationVariables) : Promise<void> {
    await this.apollo.mutate<InsertServicePricingMutation>({
      mutation: InsertServicePricingDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.ManagePricing } }
    })
  }

  async deletedServicePricingAsync (variables : DeleteServicePricingByIdMutationVariables) : Promise<void> {
    await this.apollo.mutate<DeleteServicePricingByIdMutation>({
      mutation: DeleteServicePricingByIdDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.ManagePricing } }
    })
  }

  async updateServicePricingByIdAsync (variables : UpdateServicePricingMutationVariables) : Promise<void> {
    await this.apollo.mutate<UpdateServicePricingMutation>({
      mutation: UpdateServicePricingDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.ManagePricing } }
    })
  }
}
