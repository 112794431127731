<template>
    <div v-if="canGetService" class="service-picker">
      <PartouAutocomplete
        class="rounded-xl borderless"
        :items="sortedServices"
        v-model="selectedService"
        @input="onServiceChanged"
        item-text="name"
        item-value="id"
        label="name"
        :appendIconString="'$vuetify.icons.partouChevronDown'"
        :clearable="isClearable"
        :placeholder="$t('servicePicker.searchPlaceholder')"
        :noDataText="$t('servicePicker.noData')" />
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { Service } from '@/models'
import Permission from '@/models/enums/Permission'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IAuthService } from '@/services/AuthService/IAuthService'

@Component({
  components: { PartouAutocomplete }
})
export default class ServicePicker extends Vue {
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  @Prop({ required: true, default: [] })
  services!: Service[]

  @Prop({ required: false, default: null })
  value!: Service | null

  @Prop({ required: false, default: false })
  isClearable!: boolean

  get canGetService (): boolean {
    return this.authService.hasPermission([Permission.GetService])
  }

  get sortedServices (): Array<Service> {
    return this.services.sort((a, b) => a.name && b.name && a.name > b.name ? 1 : -1)
  }

  get selectedService (): any { // eslint-disable-line @typescript-eslint/no-explicit-any
    return this.value ?? ''
  }

  set selectedService (newValue: Service) {
    // empty setter to avoid exception, value is set when parent re-renders so no actual setter is needed
  }

  @Emit('input')
  onServiceChanged (service: Service): Service {
    return service
  }
}
</script>
<style lang="scss">
@import "@/styles/variables/variables.scss";

.service-picker {
  width: 300px;

  .vs__dropdown-toggle {
    height: 52px !important;
  }

  .justify-end {
    margin-top: -4px;
  }
}

.service-picker.background-grey {
  .vs__selected-options {
  background: $partou-background !important;
  }
  .vs__dropdown-menu {
    background: $partou-background !important;
  }
}

.service-picker .vs__selected-options {
  height: 56px;

  .autocomplete {
    height: 56px !important;
  }

  .vs__selected {
    display:flex;
    font-weight: bold;
    align-items: center;
    color: $partou-primary-black  !important;
    line-height: 52px !important;
  }

  .vs__search::placeholder {
    color: $partou-primary-black-eighty !important;
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
