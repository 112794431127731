<template>
  <ActivationsCard :title="$t('services.serviceActivations.benefitCalculator.title')">
    <v-row class="activation-item-row">
      <v-col class="flex-grow-0 activation-item-col-slider">
        <v-switch inset
          ref="benefit-calculator-enabled-switch"
          :input-value="availableInCalculator"
          :disabled="!isAuthorized || (isBenefitCalculatorSliderDisabled && !availableInCalculator)"
          @change="onToggleBenefitCalculator"
        />
      </v-col>
      <v-col class="activation-item-col">
        <h3>{{ $t('services.serviceActivations.benefitCalculator.enable.title') }}</h3>
        <p>{{ $t('services.serviceActivations.benefitCalculator.enable.description') }}</p>
        <p class="error-message" v-if="isAuthorized && !isLoading && isBenefitCalculatorSliderDisabled">{{ $t('services.serviceActivations.benefitCalculator.enable.errorText') }}</p>
        <div class="mt-4" v-if="useFlexkidsProducts">
          <ActivationsChecklistItem
            :isLoading="isLoading"
            :isChecked="checklist.hasActiveBookableProducts"
            :editLink="Routes.serviceProducts"
            :editPermission="Permission.ManageProduct"
            :description="$t('services.serviceActivations.benefitCalculator.checklist.activeBookableProducts')" />
          <ActivationsChecklistItem
            :isLoading="isLoading"
            :isChecked="checklist.hasCalculatorProducts"
            :editLink="Routes.serviceProducts"
            :editPermission="Permission.ManageProduct"
            :description="$t('services.serviceActivations.benefitCalculator.checklist.calculatorProducts')" />
        </div>
        <div class="mt-4" v-else>
          <ActivationsChecklistItem
            :isLoading="isLoading"
            :isChecked="checklist.hasValidSubscriptions"
            :editLink="Routes.serviceControlVariables"
            :editPermission="Permission.ManageService"
            :description="$t('services.serviceActivations.benefitCalculator.checklist.configureSubscriptionsOrProducts')" />
        </div>
      </v-col>
    </v-row>
    <PartouDialog
      ref="published-for-benefit-calculator-dialog"
      v-if="showConfirmDialog"
      :width="400"
      :showDialog="showConfirmDialog"
      @accepted="onConfirmPublishedForChildBenefitCalculatorAsync"
      @canceled="onCancelPublishedForChildBenefitCalculator"
      :cancelTranslationString="`services.serviceActivations.benefitCalculator.${this.dialogTextBase}.cancelButton`"
      :confirmTranslationString="`services.serviceActivations.benefitCalculator.${this.dialogTextBase}.confirmButton`">
        <h2>{{ $t(`services.serviceActivations.benefitCalculator.${this.dialogTextBase}.title`) }}</h2>
        <p class="dialog-text mb-8 mt-4"> {{ $t(`services.serviceActivations.benefitCalculator.${this.dialogTextBase}.text`)}} </p>
    </PartouDialog>
  </ActivationsCard>
</template>

<script lang="ts">
import ActivationsCard from './ActivationsCard.vue'
import ActivationsChecklistItem from './ActivationsChecklistItem.vue'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import Permission from '@/models/enums/Permission'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Service, ServiceSettings } from '@/models'
import { IServiceSettingsService } from '@/services/ServiceSettingsService/IServiceSettingsService'
import { IAuthService } from '@/services/AuthService/IAuthService'
import { hasActiveBookableProducts, hasValidCalculatorProducts, hasValidSubscriptions } from './ActivationsChecklistHelpers'
import { ROUTES } from '@/router/routes'

@Component({
  components: { ActivationsCard, PartouDialog, ActivationsChecklistItem }
})
export default class BenefitCalculatorActivation extends Vue {
  serviceSettingsService: IServiceSettingsService = container.get<IServiceSettingsService>(SERVICE_IDENTIFIERS.IServiceSettingsService)
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  @Prop({ required: true })
  value!: Service

  @Prop({ required: false, default: false })
  isLoading?: boolean

  showConfirmDialog = false
  availableInCalculator = false
  originalAvailableInCalculator = false
  Permission = Permission
  Routes = ROUTES
  checklist = {
    hasActiveBookableProducts: false,
    hasCalculatorProducts: false,
    hasValidSubscriptions: false
  }

  get service (): Service {
    return this.value
  }

  get serviceSettings (): ServiceSettings {
    return this.service?.serviceSettings?.[0]
  }

  get useFlexkidsProducts (): boolean {
    return this.serviceSettings?.useFlexkidsProducts
  }

  get isAuthorized (): boolean {
    return this.authService.hasPermission([Permission.PublishService])
  }

  get isBenefitCalculatorSliderDisabled (): boolean {
    if (!this.service) {
      return true
    }

    if (this.useFlexkidsProducts) {
      return !this.checklist.hasActiveBookableProducts || !this.checklist.hasCalculatorProducts
    }

    return !this.checklist.hasValidSubscriptions
  }

  get dialogTextBase (): string {
    return this.availableInCalculator ? 'activateDialog' : 'deactivateDialog'
  }

  @Watch('value', { immediate: true, deep: true })
  onServiceChange (): void {
    this.availableInCalculator = this.serviceSettings?.availableInCalculator
    this.originalAvailableInCalculator = this.availableInCalculator

    if (this.useFlexkidsProducts) {
      this.checklist.hasActiveBookableProducts = hasActiveBookableProducts(this.service)
      this.checklist.hasCalculatorProducts = hasValidCalculatorProducts(this.service)
    } else {
      this.checklist.hasValidSubscriptions = hasValidSubscriptions(this.service)
    }
  }

  onToggleBenefitCalculator (enabled: boolean): void {
    this.originalAvailableInCalculator = this.availableInCalculator
    this.availableInCalculator = enabled
    this.showConfirmDialog = true
  }

  async onConfirmPublishedForChildBenefitCalculatorAsync (): Promise<void> {
    if (!this.service) {
      this.onCancelPublishedForChildBenefitCalculator()
      return
    }

    this.showConfirmDialog = false
    this.serviceSettings.availableInCalculator = this.availableInCalculator
    const success = await this.serviceSettingsService.setAvailableInCalculatorAsync(this.service.id, this.availableInCalculator)
    if (!success) {
      this.onCancelPublishedForChildBenefitCalculator()
    }
  }

  onCancelPublishedForChildBenefitCalculator (): void {
    this.availableInCalculator = this.originalAvailableInCalculator
    this.serviceSettings.availableInCalculator = this.availableInCalculator
    this.showConfirmDialog = false
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/switch.scss';

p {
  color: $partou-primary-black-seventy;
}

p.dialog-text {
  color: $partou-primary-black-ninety;
}
</style>
