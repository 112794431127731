<template>
  <div ref="autocomplete" class="autocomplete" :class="{ 'dropdown-opened': dropdownOpened, 'fill-icon': fillIcon, 'disabled-rotate-icon': disableRotateOfIcon }" :title="tooltip">
    <v-icon v-if="prependIconString" class="prepend-icon">{{ prependIconString }}</v-icon>
    <VueSelect
      v-on="$listeners"
      v-bind="$attrs"
      v-model="selected"
      ref="dropdown"
      :options="paginated"
      :components="components"
      :class="{ prepend: !!prependIconString, 'drop-up': dropUp, append: !!appendIconString }"
      :filterable="!usePagination"
      :disabled="disabled"
      @open="onOpen"
      @close="onClose"
      @search="(query) => (search = query)">
        <template #no-options="{ search, searching }">
          <p v-if="searching">{{ $t('autocomplete.noSearchResults', { search: `'${search}'` }) }}</p>
          <p v-else>{{ noDataText }}</p>
        </template>
        <template #list-header>
          <span class="ml-2 mt-16"></span>
        </template>
        <template #list-footer v-if="usePagination">
          <span v-show="hasNextPage" ref="load" class="loader"></span>
        </template>
    </VueSelect>
    <div class="d-flex justify-end">
      <v-icon v-if="appendIconString && !disabled" class="append-icon mr-4 mt-n7" @click="openDropdown">{{ appendIconString }}</v-icon>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VueSelect from 'vue-select'
import PartouChevronDown from '@/components/PartouComponents/Icons/PartouChevronDown.vue'
import i18n from '@/plugins/i18n'
import { debounce } from 'lodash'
import { Planner } from '@/models'

@Component({
  components: { VueSelect }
})
export default class PartouAutocomplete extends Vue {
  @Prop({ required: true })
  items!: Array<any> // eslint-disable-line @typescript-eslint/no-explicit-any

  @Prop({ required: false, default: '' })
  prependIconString? : string

  @Prop({ required: false, default: '' })
  appendIconString? : string

  @Prop({ required: false, default: i18n.t('autocomplete.noData') })
  noDataText! : string

  @Prop({ required: false, default: () => PartouChevronDown })
  openIndicator! : any // eslint-disable-line @typescript-eslint/no-explicit-any

  @Prop({ required: false, default: false })
  disableRotateOfIcon! : boolean

  @Prop({ required: false, default: false })
  fillIcon! : boolean

  @Prop({ required: false, default: true })
  usePagination! : boolean

  @Prop({ required: false, default: '' })
  tooltip? : string

  @Prop({ required: false, default: false })
  disabled? : boolean

  @Prop({ required: false })
  value!: any// eslint-disable-line @typescript-eslint/no-explicit-any

  observer: any // eslint-disable-line @typescript-eslint/no-explicit-any
  limit = 30
  search = ''
  dropUp = false
  components = { OpenIndicator: this.openIndicator }
  dropdownOpened = false
  selected = []

  get filtered (): Array<any> { // eslint-disable-line @typescript-eslint/no-explicit-any
    if (!this.search) {
      return this.items
    }
    return this.items.filter((option) => {
      const nameMatches = option.name && option.name.toLowerCase().includes(this.search.toLowerCase())
      const selectedPlanners = this.selected as Planner[]
      const encryptedNameMatches = option.encrypted_full_name &&
        option.encrypted_full_name.toLowerCase().includes(this.search.toLowerCase()) &&
        !selectedPlanners.find(x => x.encrypted_full_name.toLowerCase().includes(this.search.toLowerCase()))
      return nameMatches || encryptedNameMatches
    })
  }

  get paginated ():Array<any> { // eslint-disable-line @typescript-eslint/no-explicit-any
    if (this.usePagination) {
      return this.filtered.slice(0, this.limit)
    }

    return this.items
  }

  get hasNextPage (): boolean {
    return this.paginated.length < this.filtered.length
  }

  async onOpen ():Promise<void> {
    this.dropdownOpened = true
    if (this.hasNextPage) {
      await this.$nextTick()
      if (this.observer) {
        this.observer.observe(this.$refs.load)
      }
    }
  }

  onClose ():void {
    this.dropdownOpened = false
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  openDropdown (): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dropdown = this.$refs.dropdown as any
    if (dropdown) {
      dropdown.open = true
      const inputElement = dropdown.$el.querySelector('input')
      if (inputElement) {
        inputElement.focus()
      }
    }
  }

  async infiniteScroll (intersectionObserverEntries: Array<IntersectionObserverEntry>): Promise<void> {
    const entry = intersectionObserverEntries[0]
    if (entry.isIntersecting) {
      const ul = (entry.target as any).offsetParent // eslint-disable-line @typescript-eslint/no-explicit-any
      const scrollTop = (entry.target as any).offsetParent.scrollTop // eslint-disable-line @typescript-eslint/no-explicit-any
      this.limit += 10
      await this.$nextTick()
      ul.scrollTop = scrollTop
    }
  }

  mounted () : void {
    window.addEventListener('scroll', this.setDropDownLocation)
    window.addEventListener('resize', debounce(this.setDropDownLocation, 100))
    window.addEventListener('touchmove', this.setDropDownLocation)
    this.setDropDownLocation()

    this.observer = new IntersectionObserver(this.infiniteScroll)
  }

  beforeDestroy () : void {
    window.removeEventListener('scroll', this.setDropDownLocation)
    window.removeEventListener('resize', this.setDropDownLocation)
    window.removeEventListener('touchmove', this.setDropDownLocation)
  }

  setDropDownLocation () : void {
    if (!this.$el.getBoundingClientRect()) {
      Vue.nextTick().then(() => this.setDropDownLocation)
      return
    }

    const boundingClientRect = this.$el.getBoundingClientRect()
    const dropdownHeight = 304
    this.dropUp = boundingClientRect.top > dropdownHeight && (this.$el.getBoundingClientRect().bottom + dropdownHeight > window.innerHeight)
  }

  @Watch('value', { immediate: true })
  onValueChanged (newValue: any): void { // eslint-disable-line @typescript-eslint/no-explicit-any
    this.selected = newValue
  }
}
</script>

<style src="vue-select/dist/vue-select.css"></style>

<style lang="scss">
@import "@/styles/scrollbar.scss";
@import "@/styles/typography.scss";
@import "@/styles/variables/variables.scss";

.dropdown-opened {
  .append-icon {
    transform: rotate(180deg);
    transition: transform 200ms;
  }
}

.autocomplete {
  font-family: $body-font-family;

  .append-icon {
    width: 16px;
    height: 17px;
    z-index: 4;
  }

  .prepend-icon {
    width: 16px;
    height: 17px;
    position: absolute;
    z-index: 4;
    margin: 19px 20px;

    path {
      fill: $partou-primary-salmon !important;
    }
  }

  .vs--searchable.vs--disabled .vs__dropdown-toggle .vs__selected-options{
    background-color: var(--vs-disabled-bg);
  }

  .vs--searchable {
    .vs__open-indicator {
      cursor: pointer;

      path {
        fill: none;
        stroke: $partou-primary-salmon !important;
      }
    }
    &.vs--open .vs__open-indicator {
        transform: rotate(180deg);
        transition: transform 200ms;
      }
    &:not(.vs--open) .vs__open-indicator {
      transition: transform 200ms;
    }

    .vs__dropdown-toggle {
      min-height: 40px;
      width: 100%;
      padding-bottom: 0px;
      border: none;
      background-color: transparent;

      .vs__selected-options {
        background: $partou-primary-white;
        border-radius: 30px;
        border: 1px solid $partou-primary-black-thirty;
        z-index: 3;
        width: 99%;
        overflow: hidden;
        padding-left: 16px;

        .vs__selected {
          margin-top: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 40px;
          display: block;
        }

        .vs__search {
          border: 0;
          margin-top: 0;

          &::placeholder {
            color: $partou-primary-black-eighty;
          }
        }
      }

      .vs__actions {
        position: absolute;
        margin: auto 24px;
        z-index: 4;
        right: 0px;
        height: 100%;
      }
    }

    &.prepend {
      .vs__selected-options {
        padding-left: 48px;
      }

      &.vs--open .vs__selected-options .vs__selected {
        width: calc(100% - 96px);
      }
    }

    &.append {
      .vs__selected-options {
        padding-right: 48px;
      }

      &.vs--open .vs__selected-options .vs__selected {
        width: calc(100% - 96px);
      }
    }

    .vs__dropdown-menu {
      background: white;
      width: calc(100% - 4px);
      margin-left: 2px;
      margin-right: 2px;
      max-height: 304px;
      border: none;
      z-index: 2;
      padding-left: 0;
      margin-top: -12px;
      padding-top: 0px;
      box-shadow: $partou-elevation-small-subtle;

      .vs__dropdown-option, .vs__no-options {

        padding: 10px 24px 0 24px;
        overflow: hidden;
        text-overflow: ellipsis;

        &.vs__dropdown-option--highlight {
          background-color:rgba(0, 0, 0, 0.04);
          color: $partou-primary-black;
        }

        &.vs__dropdown-option--selected {
          background-color:rgba(0, 0, 0, 0.12);
        }

        div {
          padding-bottom: 10px;
        }
      }
    }

    &.drop-up .vs__dropdown-menu {
      transform: translateY(-100%) translateY(-28px);
      margin-top: 0px;
      padding-top: 0px;
      margin-bottom: -28px;
      padding-bottom: 28px;
    }

    &.vs--open {
      .vs__selected-options {
        z-index: 6;
      }

      .vs__actions {
        z-index: 7;
      }

      .vs__dropdown-menu {
        z-index: 5;
      }
    }
  }

  &.dropdown-opened .append-icon {
    z-index: 7;
  }

  &.dropdown-opened .prepend-icon {
    z-index: 7;
  }
}

.borderless {
  .vs--searchable {
    .vs__dropdown-toggle {
      .vs__selected-options {
        border: none !important;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
      }
    }
  }
  div:nth-child(2) {
    .v-icon {
      color: $partou-primary-black-thirty
    }
  }
}
</style>
