<template>
  <div class="row-data d-flex flex-column mt-4 mb-6">
    <div class="d-flex flex-row-space-between">
      <div class="d-flex flex-column">
        <ChildInfo :child-number="getChildNumber" />
        <ProductsInfo
          :products="products"
          :showProductsInfo="usesFlexkidsProducts()"
        />
      </div>
      <div class="d-flex flex-column justify-content-space-between">
        <SelectedDaysInfo
          :booking="booking"
          :reservations="reservations"
          :waitinglistPlaces="waitinglistPlaces"
        />
        <div class="d-flex flex-row">
          <ServiceVarietyReservations
            v-for="variety in serviceVarieties"
            :key="variety.name"
            :placement="booking.placements[0]"
            :serviceVarietyName="variety.name"
            :label="variety.label"
          />
        </div>
      </div>
      <div class="status-action-container d-flex">
        <StatusInfo :booking="booking" />
        <ActionButtons
          class="action-buttons"
          :booking="booking"
          :can-get-analysis="canGetAnalysis"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Booking, WaitingListPlace, Reservation, Product, ServiceProduct, ServiceVarietyName } from '@/models'
import { Component, Prop, Vue } from 'vue-property-decorator'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IAuthService } from '@/services/AuthService/IAuthService'
import StatusInfo from './ExpandedComponents/StatusInfo.vue'
import SelectedDaysInfo from './ExpandedComponents/SelectedDaysInfo.vue'
import ProductsInfo from './ExpandedComponents/ProductsInfo.vue'
import ChildInfo from './ExpandedComponents/ChildInfo.vue'
import ActionButtons from './ExpandedComponents/ActionButtons.vue'
import ServiceVarietyReservations from '@/pages/WaitingListPropositionPage/WaitingListPropositionTable/Expanded/ExpandedComponents/ServiceVarietyReservations.vue'
import Permission from '@/models/enums/Permission'

@Component({
  components: { ActionButtons, StatusInfo, SelectedDaysInfo, ProductsInfo, ChildInfo, ServiceVarietyReservations }
})
export default class RegistrationTableExpanded extends Vue {
  @Prop({ required: true })
  booking!: Booking

  @Prop({ required: true })
  waitinglistPlaces! : WaitingListPlace[]

  @Prop({ required: true })
  reservations! : Reservation[]

  @Prop({ required: true })
  serviceProducts!: ServiceProduct[]

  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  products: Product[] = []
  hasUnsupportedProducts = false

  serviceVarieties = [
    { name: ServiceVarietyName.VSO, label: 'startVSO' },
    { name: ServiceVarietyName.NSO, label: 'startNSO' },
    { name: ServiceVarietyName.KDV, label: 'startKDV' }
  ]

  async created () {
    this.products = this.getProducts()
  }

  get getChildNumber () {
    return this.booking.child?.number
  }

  get canGetAnalysis (): boolean {
    return this.authService.hasPermission([Permission.GetAnalysis])
  }

  usesFlexkidsProducts () : boolean {
    return this.waitinglistPlaces.some(waitingListPlace => waitingListPlace.product != null) ?? false
  }

  getProducts () : Product[] {
    const productsWaitingListPlaces = this.waitinglistPlaces
      ?.filter(waitingListPlace => waitingListPlace.product !== null && waitingListPlace.product !== undefined)
      .map(waitingListPlace => waitingListPlace.product as Product) ?? []

    if (productsWaitingListPlaces.length === 0) {
      return []
    }

    const distinctProductsMap = new Map()
    productsWaitingListPlaces.forEach(product => {
      distinctProductsMap.set(product.id, product)
    })

    const distinctProducts = Array.from(distinctProductsMap.values())
    return distinctProducts
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/helpers.scss';

.row-data {
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
}

.flex-row-space-between {
  justify-content: space-between;
  gap: 16px;
}

.d-flex-full-width {
  display: flex;
  width: 100%;
}

.action-buttons {
  align-items: end;
  padding-bottom: 30px;
  padding-left: 16px;
}

.status-action-container {
  display: flex;
}

::v-deep .data-component {
  margin-bottom: 24px;
    p {
    color: $partou-primary-black-eighty;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

::v-deep .data-labels {
  color: $partou-primary-black-seventy;
  border-bottom: 1px solid $partou-primary-salmon-fourty;
  width: fit-content;
  padding-bottom: 4px;
}
</style>
