<template>
  <ActivationsCard
    ref="allow-direct-placement"
    :title="$t('services.serviceActivations.offersAndPlacements.title')"
    :errorMessage="errorText"
    :isLoading="isLoading"
    :disabled="isNotBookable">
    <v-row class="activation-item-row">
      <v-col class="flex-grow-0 activation-item-col-slider">
        <v-switch inset
          ref="allow-direct-placement-switch"
          :input-value="allowDirectPlacement"
          :disabled="!isAuthorized || (isNotBookable && !allowDirectPlacement)"
          @change="onToggleAllowDirectPlacement"
        />
      </v-col>
      <v-col class="activation-item-col">
        <h3>{{ $t('services.serviceActivations.offersAndPlacements.enable.title') }}</h3>
        <p>{{ $t('services.serviceActivations.offersAndPlacements.enable.description') }}</p>
        <p class="error-message" v-if="isAuthorized && !isLoading && checklistItemsAreInValid">{{ $t('services.serviceActivations.offersAndPlacements.enable.errorText') }}</p>
        <div class="mt-4">
            <ActivationsChecklistItem v-if="useFlexkidsProducts"
              :isLoading="isLoading"
              :isChecked="checklist.hasActiveBookableProducts"
              :editLink="Routes.serviceProducts"
              :editPermission="Permission.ManageProduct"
              :description="$t('services.serviceActivations.offersAndPlacements.enable.checklist.activeBookableProducts')" />
            <ActivationsChecklistItem v-else
              :isLoading="isLoading"
              :isChecked="checklist.hasValidSubscriptions"
              :editLink="Routes.serviceControlVariables"
              :editPermission="Permission.ManageService"
              :description="$t('services.serviceActivations.offersAndPlacements.enable.checklist.configureSubscriptionsOrProducts')" />
            <ActivationsChecklistItem
              :isLoading="isLoading"
              :isChecked="checklist.hasPinCodesSet"
              :isRequired="false"
              :editLink="Routes.serviceOffer"
              :editPermission="Permission.ManageOffer"
              :description="$t('services.serviceActivations.offersAndPlacements.enable.checklist.pinCodes')" />
            <ActivationsChecklistItem
              :isLoading="isLoading"
              :isChecked="true"
              :isRequired="false"
              :editLink="Routes.serviceControlVariables"
              :editPermission="Permission.ManageSetting"
              :hasDefaultSetting="checklist.hasDefaultPlacementPolicy"
              :description="$t('services.serviceActivations.offersAndPlacements.enable.checklist.placementPolicy')" />
            <ActivationsChecklistItem
              :isLoading="isLoading"
              :isChecked="true"
              :isRequired="false"
              :editLink="Routes.serviceControlVariables"
              :editPermission="Permission.ManageSetting"
              :hasDefaultSetting="checklist.hasDefaultFlexMargin"
              :description="$t('services.serviceActivations.offersAndPlacements.enable.checklist.flexMargin')" />
            <ActivationsChecklistItem
              :isLoading="isLoading"
              :isChecked="true"
              :isRequired="false"
              :editLink="Routes.serviceControlVariables"
              :editPermission="Permission.ManageSetting"
              :hasDefaultSetting="checklist.hasDefaultPlanningHorizon"
              :description="$t('services.serviceActivations.offersAndPlacements.enable.checklist.planningHorizon')" />
          </div>
      </v-col>
    </v-row>
    <PartouDialog
      ref="allow-direct-placement-dialog"
      v-if="showAllowDirectPlacementConfirmDialog"
      :width="400"
      :showDialog="showAllowDirectPlacementConfirmDialog"
      @accepted="onConfirmAllowDirectPlacementAsync"
      @canceled="onCancelAllowDirectPlacement"
      :cancelTranslationString="`services.serviceActivations.offersAndPlacements.enable.${this.dialogAllowDirectPlacementTextBase}.cancelButton`"
      :confirmTranslationString="`services.serviceActivations.offersAndPlacements.enable.${this.dialogAllowDirectPlacementTextBase}.confirmButton`">
        <h2>{{ $t(`services.serviceActivations.offersAndPlacements.enable.${this.dialogAllowDirectPlacementTextBase}.title`) }} </h2>
        <p class="dialog-text mb-8 mt-4"> {{ $t(`services.serviceActivations.offersAndPlacements.enable.${this.dialogAllowDirectPlacementTextBase}.text`)}} </p>
    </PartouDialog>
  </ActivationsCard>
</template>

<script lang="ts">
import ActivationsCard from './ActivationsCard.vue'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import Permission from '@/models/enums/Permission'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Service, ServiceSettings } from '@/models'
import { IServiceSettingsService } from '@/services/ServiceSettingsService/IServiceSettingsService'
import { IOfferService } from '@/services/OfferService/IOfferService'
import { IAuthService } from '@/services/AuthService/IAuthService'
import i18n from '@/plugins/i18n'
import { ROUTES } from '@/router/routes'
import ActivationsChecklistItem from './ActivationsChecklistItem.vue'
import { hasActiveBookableProducts, hasDefaultFlexMargin, hasDefaultPlacementPolicy, hasDefaultPlanningHorizon, hasPinCodesSet, hasValidSubscriptions } from './ActivationsChecklistHelpers'

@Component({
  components: { ActivationsCard, ActivationsChecklistItem, PartouDialog }
})
export default class OffersAndPlacementsActivation extends Vue {
  serviceSettingsService: IServiceSettingsService = container.get<IServiceSettingsService>(SERVICE_IDENTIFIERS.IServiceSettingsService)
  offerService: IOfferService = container.get<IOfferService>(SERVICE_IDENTIFIERS.IOfferService)
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  @Prop({ required: true })
  public value!: Service

  @Prop({ required: false, default: false })
  isLoading?: boolean

  allowDirectPlacement = false
  originalAllowDirectPlacement = false
  showAllowDirectPlacementConfirmDialog = false

  Permission = Permission
  Routes = ROUTES
  checklist = {
    hasActiveBookableProducts: false,
    hasValidSubscriptions: false,
    hasPinCodesSet: false,
    hasDefaultPlacementPolicy: true,
    hasDefaultFlexMargin: true,
    hasDefaultPlanningHorizon: true
  }

  get service (): Service {
    return this.value
  }

  get serviceSettings (): ServiceSettings {
    return this.service?.serviceSettings?.[0]
  }

  get useFlexkidsProducts (): boolean {
    return this.serviceSettings?.useFlexkidsProducts
  }

  get isAuthorized (): boolean {
    return this.authService.hasPermission([Permission.PublishService])
  }

  get isNotBookable (): boolean {
    if (!this.service || !this.serviceSettings?.bookableInSelectionGuide) {
      return true
    }
    return this.checklistItemsAreInValid
  }

  get checklistItemsAreInValid (): boolean {
    if (this.useFlexkidsProducts) {
      return !this.checklist.hasActiveBookableProducts
    }

    return !this.checklist.hasValidSubscriptions
  }

  get dialogAllowDirectPlacementTextBase (): string {
    return this.allowDirectPlacement ? 'activateDialog' : 'deactivateDialog'
  }

  get errorText (): string | undefined {
    return this.isAuthorized && this.serviceSettings && !this.serviceSettings.bookableInSelectionGuide ? i18n.t('services.serviceActivations.offersAndPlacements.errorText').toString() : undefined
  }

  @Watch('value', { immediate: true, deep: true })
  onServiceChange (): void {
    this.allowDirectPlacement = this.service?.serviceSettings?.[0]?.allowDirectPlacement ?? false
    this.originalAllowDirectPlacement = this.allowDirectPlacement

    this.checklist.hasActiveBookableProducts = hasActiveBookableProducts(this.service)
    this.checklist.hasValidSubscriptions = hasValidSubscriptions(this.service)
    this.checklist.hasPinCodesSet = hasPinCodesSet(this.service)
    this.checklist.hasDefaultPlacementPolicy = hasDefaultPlacementPolicy(this.service)
    this.checklist.hasDefaultFlexMargin = hasDefaultFlexMargin(this.service)
    this.checklist.hasDefaultPlanningHorizon = hasDefaultPlanningHorizon(this.service)
  }

  onToggleAllowDirectPlacement (enabled: boolean): void {
    this.originalAllowDirectPlacement = this.allowDirectPlacement
    this.allowDirectPlacement = enabled
    this.showAllowDirectPlacementConfirmDialog = true
  }

  async onConfirmAllowDirectPlacementAsync (): Promise<void> {
    if (!this.serviceSettings) {
      this.onCancelAllowDirectPlacement()
      return
    }

    this.showAllowDirectPlacementConfirmDialog = false
    await this.updateAllowDirectPlacementAsync()
  }

  onCancelAllowDirectPlacement (): void {
    this.allowDirectPlacement = this.originalAllowDirectPlacement
    this.serviceSettings.allowDirectPlacement = this.allowDirectPlacement
    this.showAllowDirectPlacementConfirmDialog = false
  }

  async updateAllowDirectPlacementAsync (): Promise<void> {
    this.serviceSettings.allowDirectPlacement = this.allowDirectPlacement
    const success = await this.serviceSettingsService.setAllowDirectPlacementAsync(this.service.id, this.allowDirectPlacement)
    if (!success) {
      this.onCancelAllowDirectPlacement()
      return
    }

    // If allowDirectPlacement is enabled then CapacityChangedOn needs to be set so that the capacities will be calculated again.
    if (this.allowDirectPlacement) {
      await this.offerService.updateCapacityChangedOnForOfferByServiceIdAsync(this.service.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/switch.scss';

p {
  color: $partou-primary-black-seventy;
}

p.dialog-text {
  color: $partou-primary-black-ninety;
}
</style>
