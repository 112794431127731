<template>
  <v-card class="rounded-xl card pa-8 full-width" :flat="true">
    <v-card-text>
      <div class="d-flex header">
        <h1>{{$t('services.products.chooseCalculatorProduct.title')}}</h1> <v-icon>mdi-calculator</v-icon>
      </div>
      <div class="description">
        <p class="mt-4" v-html="$t('services.products.chooseCalculatorProduct.description')"></p>
        <h3 v-html="$t('services.products.chooseCalculatorProduct.warningBso')"></h3>
      </div>

      <div class="mt-6 d-flex">
        <div class="product-selection">
          <p class="ml-6" v-html="$t('services.products.chooseCalculatorProduct.nso')"></p>
          <PartouAutocomplete
            class="rounded-m borderless"
            item-text="name"
            item-value="id"
            label="name"
            name="selectProductNso"
            :value="selectedProductNso"
            :items="productsNso"
            :appendIconString="'mdi-chevron-down'"
            :clearable="true"
            :placeholder="$t('services.products.chooseCalculatorProduct.productSelectPlaceholder')"
            :noDataText="$t('services.products.chooseCalculatorProduct.productSelectNoData')"
            @input="nsoSelectionChanged" />
        </div>
        <div class="product-selection">
          <p class="ml-6" v-html="$t('services.products.chooseCalculatorProduct.vso')"></p>
          <PartouAutocomplete
            class="rounded-m borderless"
            item-text="name"
            item-value="id"
            label="name"
            name="selectProductVso"
            :value="selectedProductVso"
            :items="productsVso"
            :appendIconString="'mdi-chevron-down'"
            :clearable="true"
            :disabled="selectedProductNso === null"
            :placeholder="$t('services.products.chooseCalculatorProduct.productSelectPlaceholderVso')"
            :noDataText="$t('services.products.chooseCalculatorProduct.productSelectNoData')"
            @input="vsoSelectionChanged" />
        </div>
      </div>
      <PartouDialog
        :width="600"
        v-if="showConfirmRemoveDialog"
        :showDialog="showConfirmRemoveDialog"
        @accepted="onShowConfirmRemoveDialogConfirmed"
        @canceled="onShowConfirmRemoveDialogCanceled"
        :cancelTranslationString="'services.products.table.expanded.removeCalculationProduct.cancel'"
        :confirmTranslationString="'services.products.table.expanded.removeCalculationProduct.remove'">
          <div class="d-flex flex-row dialog-header">
            <v-icon class="exclamation-mark-circled">$vuetify.icons.partouExclamationMarkCircledThinError</v-icon>
            <h2 v-html="$t('services.products.table.expanded.removeCalculationProduct.title')"></h2>
          </div>
          <p class="mb-6 mt-6" v-html="$t('services.products.table.expanded.removeCalculationProduct.description')"></p>
      </PartouDialog>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator'
import { Product, ProductCategory, ServiceKind, ServiceProduct } from '@/models'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import ServiceVarietyName from '@/models/enums/ServiceVarietyName'
import { IProductService } from '@/services/ProductService/IProductService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'

@Component({
  components: { PartouAutocomplete, PartouDialog }
})
export default class BenefitCalculatorProductSelection extends Vue {
  productService: IProductService = container.get<IProductService>(SERVICE_IDENTIFIERS.IProductService)

  productsVso: Product[] = []
  productsNso: Product[] = []
  selectedProductVso: Product|null = null
  selectedProductNso: Product|null = null
  originalNsoProduct: Product|null = null
  changedVariety = ServiceVarietyName.NSO
  showConfirmRemoveDialog = false

  serviceKinds = ServiceKind
  serviceVarieties = ServiceVarietyName

  @Prop({ required: true })
  serviceId!: string

  @Prop({ required: true })
  availableServiceProducts!: Partial<ServiceProduct>[]

  @Prop({ required: true })
  isChildBenefitCalculatorActive!: boolean

  @Prop({ required: true })
  usesFlexkidsProducts!: boolean

  @Watch('availableServiceProducts', { immediate: true, deep: true })
  @Watch('serviceKind', { immediate: true })
  onItemsChanged () {
    this.updateSelectableProducts()
  }

  @Emit('onSelectedCalculatorProductChanged')
  onSelectedCalculatorProductChanged () : boolean {
    return this.selectedProductNso === null && this.usesFlexkidsProducts
  }

  async mounted () : Promise<void> {
    this.updateSelectableProducts()
  }

  updateSelectableProducts () {
    const currentDate: Date = new Date()
    this.productsVso = []
    this.productsNso = []
    this.selectedProductVso = null
    this.selectedProductNso = null

    this.availableServiceProducts.forEach((sp: Partial<ServiceProduct>) => {
      if (sp.product && new Date(sp.validFrom) < currentDate) {
        if (sp.product?.serviceVariety === ServiceVarietyName.VSO && sp.product?.category === ProductCategory.SchoolWeeks) {
          this.productsVso.push(sp.product)
          if (sp.isUsedInChildBenefitCalculator) {
            this.selectedProductVso = sp.product
          }
        } else if (sp.product?.serviceVariety === ServiceVarietyName.NSO && sp.product?.category === ProductCategory.AllWeeks) {
          this.productsNso.push(sp.product)
          if (sp.isUsedInChildBenefitCalculator) {
            this.selectedProductNso = sp.product
          }
        }
      }
    })
  }

  async vsoSelectionChanged (selectedProductVso: Product|null) {
    this.updateProductSelection(selectedProductVso, ServiceVarietyName.VSO)
  }

  async nsoSelectionChanged (selectedProductNso: Product|null) {
    if (this.isChildBenefitCalculatorActive && !selectedProductNso && this.usesFlexkidsProducts) {
      this.changedVariety = ServiceVarietyName.NSO
      this.originalNsoProduct = this.selectedProductNso
      this.selectedProductNso = null
      this.showConfirmRemoveDialog = true
    } else {
      this.updateProductSelection(selectedProductNso, ServiceVarietyName.NSO)
    }
  }

  onShowConfirmRemoveDialogConfirmed () {
    this.showConfirmRemoveDialog = false
    this.updateProductSelection(null, this.changedVariety)
  }

  onShowConfirmRemoveDialogCanceled () {
    this.selectedProductNso = this.originalNsoProduct
    this.showConfirmRemoveDialog = false
  }

  async updateProductSelection (selectedProduct: Product|null, serviceVariety: ServiceVarietyName) {
    await this.productService.DeactivateIsUsedInChildBenefitCalculatorForService(this.serviceId)

    const productVso = serviceVariety === ServiceVarietyName.VSO ? selectedProduct : this.selectedProductVso
    if (productVso) {
      await this.productService.ActivateIsUsedInChildBenefitCalculatorAsync(this.serviceId, productVso.id)
    }

    const productNso = serviceVariety === ServiceVarietyName.NSO ? selectedProduct : this.selectedProductNso
    if (productNso) {
      await this.productService.ActivateIsUsedInChildBenefitCalculatorAsync(this.serviceId, productNso.id)
    }

    this.$nextTick(() => {
      this.selectedProductVso = productVso
      this.selectedProductNso = productNso
      this.onSelectedCalculatorProductChanged()
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
.header {
  gap: 16px;
}

.v-icon {
  color: $partou-primary-blue;
  font-size: 28px;
}

h2 {
  color: $partou-secondary-bordeaux !important;
  margin-bottom: 16px;
}

.description {
  display: flex;
  flex-direction: column;
}

.product-selection {
  margin-right: 16px;
  width: 400px;
}

.dialog-header {
  align-items: center;
  gap: 8px;

  h2 {
    color: $partou-primary-salmon !important;
    margin: 0;
  }
}
</style>
