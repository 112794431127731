<template>
  <v-card class="rounded-xl card pa-8 full-width" :flat="true">
    <v-card-text>
      <div class="d-flex header">
        <h1>{{$t('services.products.chooseCalculatorProduct.title')}}</h1> <v-icon>mdi-calculator</v-icon>
      </div>
      <div class="description">
        <p class="mt-4" v-html="$t('services.products.chooseCalculatorProduct.description')"></p>
        <h3 v-html="$t('services.products.chooseCalculatorProduct.warningKdv')"></h3>
      </div>
      <div class="mt-6 d-flex">
        <div class="product-selection">
          <p class="ml-6" v-html="$t('services.products.chooseCalculatorProduct.kdv')"></p>
          <PartouAutocomplete
            class="rounded-xl borderless"
            item-text="name"
            item-value="id"
            label="name"
            name="selectProductKdv"
            :value="selectedProductKdv"
            :items="productsKdv"
            :appendIconString="'mdi-chevron-down'"
            :clearable="true"
            :placeholder="$t('services.products.chooseCalculatorProduct.productSelectPlaceholder')"
            :noDataText="$t('services.products.chooseCalculatorProduct.productSelectNoData')"
            @input="selectionChanged" />
        </div>
      </div>
      <PartouDialog
        :width="600"
        v-if="showConfirmRemoveDialog"
        :showDialog="showConfirmRemoveDialog"
        @accepted="onShowConfirmRemoveDialogConfirmed"
        @canceled="onShowConfirmRemoveDialogCanceled"
        :cancelTranslationString="'services.products.table.expanded.removeCalculationProduct.cancel'"
        :confirmTranslationString="'services.products.table.expanded.removeCalculationProduct.remove'">
          <div class="d-flex flex-row dialog-header">
            <v-icon class="exclamation-mark-circled">$vuetify.icons.partouExclamationMarkCircledThinError</v-icon>
            <h2 v-html="$t('services.products.table.expanded.removeCalculationProduct.title')"></h2>
          </div>
          <p class="mb-6 mt-6" v-html="$t('services.products.table.expanded.removeCalculationProduct.description')"></p>
      </PartouDialog>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator'
import { Product, ProductCategory, ServiceKind, ServiceProduct } from '@/models'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import ServiceVarietyName from '@/models/enums/ServiceVarietyName'
import { IProductService } from '@/services/ProductService/IProductService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'

@Component({
  components: { PartouAutocomplete, PartouDialog }
})
export default class BenefitCalculatorProductSelection extends Vue {
  productService: IProductService = container.get<IProductService>(SERVICE_IDENTIFIERS.IProductService)

  productsKdv: Product[] = []
  selectedProductKdv: Product|null = null
  originalKdvProduct: Product|null = null
  showConfirmRemoveDialog = false

  serviceKinds = ServiceKind
  serviceVarieties = ServiceVarietyName

  @Prop({ required: true })
  serviceId!: string

  @Prop({ required: true })
  isChildBenefitCalculatorActive!: boolean

  @Prop({ required: true })
  availableServiceProducts!: Partial<ServiceProduct>[]

  @Prop({ required: true })
  usesFlexkidsProducts!: boolean

  @Watch('availableServiceProducts', { immediate: true, deep: true })
  @Watch('serviceKind', { immediate: true })
  onItemsChanged () {
    this.updateSelectableProducts()
  }

  @Emit('onSelectedCalculatorProductChanged')
  onSelectedCalculatorProductChanged () : boolean {
    return this.selectedProductKdv === null && this.usesFlexkidsProducts
  }

  async mounted () : Promise<void> {
    this.updateSelectableProducts()
  }

  updateSelectableProducts () {
    const currentDate: Date = new Date()
    this.productsKdv = []
    this.selectedProductKdv = null

    this.availableServiceProducts.forEach((sp: Partial<ServiceProduct>) => {
      if (sp.product &&
          sp.product?.category === ProductCategory.AllWeeks &&
          new Date(sp.validFrom) < currentDate &&
          sp.product?.serviceVariety === ServiceVarietyName.KDV) {
        this.productsKdv.push(sp.product)
        if (sp.isUsedInChildBenefitCalculator) {
          this.selectedProductKdv = sp.product
        }
      }
    })
  }

  async selectionChanged (selectedProduct: Product) {
    if (this.isChildBenefitCalculatorActive && !selectedProduct && this.usesFlexkidsProducts) {
      this.originalKdvProduct = this.selectedProductKdv
      this.selectedProductKdv = null
      this.showConfirmRemoveDialog = true
    } else {
      this.updateProductSelection(selectedProduct)
    }
  }

  onShowConfirmRemoveDialogConfirmed () {
    this.showConfirmRemoveDialog = false
    this.updateProductSelection(null)
  }

  onShowConfirmRemoveDialogCanceled () {
    this.selectedProductKdv = this.originalKdvProduct
    this.showConfirmRemoveDialog = false
  }

  async updateProductSelection (selectedProduct: Product|null) {
    await this.productService.DeactivateIsUsedInChildBenefitCalculatorForService(this.serviceId)
    this.selectedProductKdv = selectedProduct
    if (this.selectedProductKdv) {
      await this.productService.ActivateIsUsedInChildBenefitCalculatorAsync(this.serviceId, this.selectedProductKdv.id)
    }
    this.onSelectedCalculatorProductChanged()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
.header {
  gap: 16px;
}

.v-icon {
  color: $partou-primary-blue;
  font-size: 28px;
}

h2 {
  color: $partou-secondary-bordeaux !important;
  margin-bottom: 16px;
}

.description {
  display: flex;
  flex-direction: column;
}

.product-selection {
  margin-right: 16px;
  width: 400px;
}

.dialog-header {
  align-items: center;
  gap: 8px;

  h2 {
    color: $partou-primary-salmon !important;
    margin: 0;
  }
}
</style>
