<template>
    <div>
      <h2 class="mb-1 black-ninety">{{$t('services.controlVariables.useBkrPolicy.title')}}</h2>
      <div class="action-container">
        <div class="text-container">
          <p>{{$t('services.controlVariables.useBkrPolicy.useBkrPolicyEnabled')}}</p>
        </div>
        <div class="controls-container d-flex flex-row">
          <v-switch ref="bkr-policy-switch" class="switch mt-0 mb-0 pt-0"  v-model="useBkrPolicy" :disabled="!editEnabled"/>
          <p>{{$t('services.controlVariables.useBkrPolicy.label')}}</p>
        </div>
      </div>
    </div>
  </template>

<script lang="ts">
import { Service } from '@/models'
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'

@Component({
  components: { PartouDialog }
})
export default class BkrPolicyFeatureToggle extends Vue {
  @Prop({ required: true })
  service!: Service

  @VModel({ required: true })
  useBkrPolicy! : boolean

  @Prop({ required: true, default: [] })
  editEnabled! : boolean

  loading = false
}
</script>

<style lang="scss" scoped>
.action-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .text-container {
    flex: 1;
  }

  .controls-container {
    width: 50%;
    display: flex;
    gap: 10px;
  }
}
</style>
