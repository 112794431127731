<template>
  <div>
    <v-row class="filter-row pl-6 pr-6">
      <v-col class="pl-2 pr-2 flex-grow-0">
        <span class="ml-6 autocomplete-label">{{$t('pages.audit.filters.category.header')}}</span>
        <PartouAutocomplete
          class="filter-dropdown filter-dropdown-auto-grow borderless"
          :items="typeFilterItems"
          :v-model="filterSettings.category"
          item-text="name"
          item-value="id"
          label="name"
          :appendIconString="'$vuetify.icons.partouChevronDown'"
          :clearable="true"
          :placeholder="$t('pages.audit.filters.category.placeholder')"
          @input="onCategoryFilterChanged"
        />
      </v-col>
      <v-col v-if="hasCategorySubFilter && !isSmallScreen" class="filter-separator pl-2 pr-2 flex-grow-0"><div /></v-col>
      <v-col class="pl-2 pr-2 pt-0 flex-grow-1">
        <component
          :is="categorySubFilter"
          :service="filterSettings.service"
          v-model="filterSettings.categorySubFilter"
          v-if="hasCategorySubFilter && !isSmallScreen"
          ref="sub-filter-component"
          @input="onFilterChanged"
        />
      </v-col>
      <v-col class="flex-grow-0">
        <span class="ml-6 autocomplete-label">{{$t('pages.audit.filters.actor.header')}}</span>
        <PartouAutocomplete
          ref="actor-filter"
          class="filter-dropdown filter-dropdown-auto-grow borderless"
          :items="actorFilterItems"
          item-text="name"
          item-value="id"
          label="name"
          :tooltip="actorNameTooltip"
          :appendIconString="'$vuetify.icons.partouChevronDown'"
          :clearable="true"
          :placeholder="$t('pages.audit.filters.actor.placeholder')"
          @input="onActorFilterChanged"
        />
      </v-col>
      <v-col class="pl-0 flex-grow-0">
        <span class="ml-6 autocomplete-label">{{$t('pages.audit.filters.period.header')}}</span>
        <div class="date-filter-row">
          <DateInputField
            class="filter-dropdown"
            :date="filterSettings.startDate"
            :maxDate="maxStartDate"
            :placeholder="$t('pages.audit.filters.period.placeholderFrom')"
            :allowWeekends="true"
            v-model="filterSettings.startDate"
            :ignoreInitDateChange="true"
            @input="onFilterChanged"
            :isClearable="true"
          />
          <span class="date-filter-separator">-</span>
          <DateInputField
            class="filter-dropdown"
            :date="filterSettings.endDate"
            :minDate="filterSettings.startDate"
            :maxDate="maxEndDate"
            :placeholder="$t('pages.audit.filters.period.placeholderUntil')"
            :disabled="false"
            :startAtMinDate="true"
            :allowWeekends="true"
            v-model="filterSettings.endDate"
            :ignoreInitDateChange="true"
            @input="onFilterChanged"
            :isClearable="true"
          />
        </div>
      </v-col>
    </v-row>
    <v-row v-if="hasCategorySubFilter && isSmallScreen" class="filter-row pl-6 pr-6 mt-2">
      <v-col class="pl-2  flex-grow-1">
        <component
          :is="categorySubFilter"
          :service="filterSettings.service"
          v-model="filterSettings.categorySubFilter"
          ref="sub-filter-component"
          @input="onFilterChanged"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import DateInputField from '@/components/InputFields/DateInputField/DateInputField.vue'
import ServiceAuditTableFiltersProduct from '@/features/ServiceAudit/components/AuditCategoryFilters/ServiceAuditTableFiltersProduct.vue'
import i18n from '@/plugins/i18n'
import moment from 'moment'
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { AuditCategoryFilter, ServiceAuditUser } from '@/models'
import { AUDIT_SYSTEM_ACTOR } from '@/constants/constants'
import { ServiceAuditFilterSettings } from '@/models/Audit'

const SMALL_SCREEN_WIDTH = 1903

type DropdownFilterItem = {
  id: string,
  name: string
}

@Component({
  components: { PartouAutocomplete, DateInputField, ServiceAuditTableFiltersProduct }
})
export default class ServiceAuditTableFilters extends Vue {
  @Prop({ required: true })
  value!: ServiceAuditFilterSettings

  @Prop({ required: false, default: [] })
  users!: ServiceAuditUser[]

  filterSettings: ServiceAuditFilterSettings = { ...this.value }
  actorNameTooltip = ''
  isSmallScreen = false

  get maxStartDate (): Date {
    const now = moment().toDate()
    if (!this.filterSettings.endDate || this.filterSettings.endDate >= now) {
      return now
    }

    return this.filterSettings.endDate
  }

  get maxEndDate (): Date {
    return moment().toDate()
  }

  get typeFilterItems (): string[] {
    return Object.keys(AuditCategoryFilter).map(key => i18n.t(`pages.audit.filters.category.filter${key}`).toString())
  }

  get actorFilterItems (): DropdownFilterItem[] {
    if (this.users.length === 0) {
      return []
    }

    const userList = this.users.map((user) => {
      return {
        id: user.userId,
        name: user.userName
      }
    })

    userList.push({
      id: AUDIT_SYSTEM_ACTOR,
      name: i18n.t('pages.audit.filters.actor.systemUser').toString()
    })

    return userList
  }

  get hasCategorySubFilter (): boolean {
    return this.filterSettings.category !== null
  }

  get categorySubFilter (): string | null {
    if (this.filterSettings.category === AuditCategoryFilter.Product) {
      return 'ServiceAuditTableFiltersProduct'
    }

    return null
  }

  @Watch('value', { immediate: true, deep: true })
  onFiltersChange (newValue: ServiceAuditFilterSettings): void {
    this.filterSettings = { ...newValue }
  }

  onCategoryFilterChanged (auditCategory: AuditCategoryFilter | null): void {
    this.filterSettings = {
      ...this.filterSettings,
      category: auditCategory,
      categorySubFilter: undefined
    }
    this.$emit('input', this.filterSettings)
  }

  onFilterChanged (): void {
    this.$emit('input', this.filterSettings)
  }

  onCategorySubFilterChanged (): void {
    this.$emit('input', this.filterSettings)
  }

  onActorFilterChanged (actor: DropdownFilterItem | null): void {
    this.actorNameTooltip = actor?.name ?? ''
    this.$emit('input', { ...this.value, actor: actor?.id })
  }

  beforeMount (): void {
    window.addEventListener('resize', this.handleWindowResize)
    this.handleWindowResize()
  }

  beforeDestroy (): void {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleWindowResize (): void {
    this.isSmallScreen = window.innerWidth <= SMALL_SCREEN_WIDTH
  }
}
</script>

<style lang="scss">
@import "@/styles/variables/variables.scss";
.filter-row {
  max-height: 64px !important;

  .date-filter-row {
    display: inline-flex;
    width: auto;

    .date-filter-separator {
      color: $partou-primary-black-fourty;
      padding: 0 8px;
      line-height: 50px;
    }
  }
}

.filter-dropdown {
  width: 200px;
}

.dateInputField {
  width: 250px;
}

.filter-dropdown-auto-grow {
  .vs__dropdown-menu {
    min-width: 200px;
    width: auto !important;
  }
}

.filter-separator {
  align-content: center;

  div {
    border-left: solid 1px $partou-primary-black-fourty;
    width: 1px;
    height: 40px;
  }
}

.vs__dropdown-toggle {
  height: 40px !important;
}
</style>
