<template>
  <div class="row-data d-flex flex-column mt-4 mb-6">
    <div class="d-flex flex-row-space-between">
      <div class="d-flex flex-column">
        <ChildInfo :child-number="getChildNumber" />
        <ProductsInfo
        :products="products"
        :showProductsInfo="usesFlexkidsProducts()"
        :service-products="serviceProducts"
        :initiallyUsedFlexkidsProducts="initiallyUsedFlexkidsProducts"
        />
      </div>

      <div class="d-flex flex-column">
        <SelectedDaysInfo :current-proposition="currentProposition" />
        <div class="d-flex flex-row">
          <ServiceVarietyReservations
            v-for="variety in serviceVarieties"
            :key="variety.name"
            :placement="currentProposition.placement"
            :service-variety-name="variety.name"
            :label="variety.label"
          />
        </div>
      </div>

      <StatusInfo :current-proposition="currentProposition" />
    </div>

    <span class="divider"></span>

    <div class="d-flex-full-width flex-row align-items-start max-height-45">
      <PriorityInfo
        :current-proposition="currentProposition"
        :possible-priorities="possiblePriorities"
      />

      <CancelReasonData
        :current-proposition="currentProposition"
        class="cancel-reason-data"
      />

      <ActionButtons
        class="action-buttons"
        :can-manage-waiting-list="canManageWaitingList"
        :can-get-analysis="canGetAnalysis"
        :current-proposition="currentProposition"
        :can-propose="canPropose"
        @cancel-clicked="onCancelClicked"
        @propose-clicked="onProposeClicked"
        @revoke-clicked="onRevokeClicked"
        @extend-clicked="onExtendClicked"
      />
    </div>

    <ProposePropositionDialog
      v-if="showProposeDialog"
      :show-propose-dialog="showProposeDialog"
      :current-proposition="currentProposition"
      :planner-action-error="plannerActionError"
      :OnWaitinglistPropositionProposedAsync="OnWaitinglistPropositionProposedAsync"
      @canceled="onPropositionActionCancelled"
    />

    <CancelPropositionDialog
      v-if="showCancelDialog"
      :show-cancel-dialog="showCancelDialog"
      :current-proposition="currentProposition"
      :cancel-reasons="cancelReasons"
      :comment-max-length="commentMaxLength"
      :planner-action-error="plannerActionError"
      :OnWaitinglistPropositionCancelledAsync="OnWaitinglistPropositionCancelledAsync"
      @canceled="onPropositionActionCancelled"
      @causedByReasonTypeChanged="onCausedByReasonTypeChanged"
      @causedByReasonCommentChanged="onCausedByReasonCommentChanged"
    />

    <RevokePropositionDialog
      v-if="showRevokeDialog"
      :show-revoke-dialog="showRevokeDialog"
      :current-proposition="currentProposition"
      :revoke-reasons="revokeReasons"
      :comment-max-length="commentMaxLength"
      :planner-action-error="plannerActionError"
      :OnWaitinglistPropositionRevokedAsync="OnWaitinglistPropositionRevokedAsync"
      @canceled="onPropositionActionCancelled"
      @causedByReasonTypeChanged="onCausedByReasonTypeChanged"
      @causedByReasonCommentChanged="onCausedByReasonCommentChanged"
    />
    <ExtendPropositionDialog
      v-if="showExtendDialog"
      :show-extend-dialog="showExtendDialog"
      :current-proposition="currentProposition"
      :planner-action-error="plannerActionError"
      :OnWaitinglistPropositionExtendedAsync="OnWaitinglistPropositionExtendedAsync"
      @canceled="onPropositionActionCancelled"
      @extendedDateChanged="onExtendedDateChanged"
    />
  </div>
</template>

<script lang="ts">
import { ServiceVarietyName, ServiceKind, WaitingListProposition, Product, ServiceProduct } from '@/models'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import PartouStepper from '@/components/PartouComponents/PartouStepper.vue'
import Permission from '@/models/enums/Permission'
import { PlannerCausedByReason } from '@/models/enums/PlannerCausedBy'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IAuthService } from '@/services/AuthService/IAuthService'
import { IWaitingListPropositionService } from '@/services/WaitingListPropositionService/IWaitingListPropositionService'
import { IConfigurationService } from '@/services/ConfigurationService/IConfigurationService'
import PartouFloatingCircleButton from '@/components/PartouComponents/PartouFloatingCircleButton.vue'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import PartouTextArea from '@/components/PartouComponents/PartouTextArea.vue'
import TextAreaFieldRow from '@/components/TextAreaFieldRow'
import PartouButton from '@/components/PartouComponents/PartouButton.vue'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import WaitingListPlacePriority from '@/models/enums/WaitingListPlacePriority'
import { PriorityOption } from '@/pages/ServicesPage/WaitingList/PriorityOption'
import { WaitingListPropositionResponse } from '@/services/WaitingListPropositionService/WaitingListPropositionResponse'
import { IPropositionService } from '@/services/PropositionService/IPropositionService'
import ChildInfo from './ExpandedComponents/ChildInfo.vue'
import ServiceVarietyReservations from './ExpandedComponents/ServiceVarietyReservations.vue'
import ProductsInfo from './ExpandedComponents/ProductsInfo.vue'
import SelectedDaysInfo from './ExpandedComponents/SelectedDaysInfo.vue'
import StatusInfo from './ExpandedComponents/StatusInfo.vue'
import PriorityInfo from './ExpandedComponents/PriorityInfo.vue'
import CancelReasonData from './ExpandedComponents/CancelReasonData.vue'
import ActionButtons from './ExpandedComponents/ActionButtons.vue'
import CancelPropositionDialog from './ExpandedComponents/Dialogs/CancelPropositionDialog.vue'
import ProposePropositionDialog from './ExpandedComponents/Dialogs/ProposePropositionDialog.vue'
import RevokePropositionDialog from './ExpandedComponents/Dialogs/RevokePropositionDialog.vue'
import ExtendPropositionDialog from './ExpandedComponents/Dialogs/ExtendPropositionDialog.vue'

@Component({
  components: { ProposePropositionDialog, CancelPropositionDialog, RevokePropositionDialog, ExtendPropositionDialog, ActionButtons, CancelReasonData, PriorityInfo, StatusInfo, SelectedDaysInfo, ProductsInfo, ServiceVarietyReservations, ChildInfo, DaysCoverage, PartouStepper, PartouFloatingCircleButton, PartouAutocomplete, PartouButton, PartouDialog, TextAreaFieldRow, PartouTextArea }
})
export default class WaitingListPropositionTableExpanded extends Vue {
  @Prop({ required: true })
  proposition!: WaitingListProposition

  @Prop({ required: true })
  serviceProducts!: ServiceProduct[]

  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)
  waitingListPropositionService: IWaitingListPropositionService = container.get<IWaitingListPropositionService>(SERVICE_IDENTIFIERS.IWaitingListPropositionService)
  propositionService: IPropositionService = container.get<IPropositionService>(SERVICE_IDENTIFIERS.IPropositionService)

  ServiceVarietyName = ServiceVarietyName
  ServiceKind = ServiceKind

  showProposeDialog = false
  showCancelDialog = false
  showRevokeDialog = false
  showExtendDialog = false

  causedByReasonComment = ''
  causedByReasonType = ''
  commentMaxLength = 125
  cancelReasons: PlannerCausedByReason[] = []
  revokeReasons: PlannerCausedByReason[] = []
  possiblePriorities: PriorityOption[] = []
  products: Product[] = []
  currentProposition!: WaitingListProposition
  plannerActionError = false
  extendedDate: Date | null = null

  serviceVarieties = [
    { name: ServiceVarietyName.NSO, label: 'startNSO' },
    { name: ServiceVarietyName.VSO, label: 'startVSO' },
    { name: ServiceVarietyName.KDV, label: 'startKDV' }
  ]

  get getChildNumber () {
    return this.currentProposition.locationInquiry?.child?.number
  }

  get initiallyUsedFlexkidsProducts () {
    return this.currentProposition.placement?.initiallyUsedFlexkidsProducts ?? false
  }

  async created () {
    this.currentProposition = this.proposition
    this.products = this.getProducts()
    for (const waitingListPlacePriority in WaitingListPlacePriority) {
      this.possiblePriorities.push({ value: waitingListPlacePriority, text: this.$t(`waitingListPropositions.table.priority.${waitingListPlacePriority.toLowerCase()}`).toString() })
    }
  }

  mounted () {
    this.getConfigurationTableDataAsync()
  }

  get canManageWaitingList () : boolean {
    return this.authService.hasPermission([Permission.ManageWaitingList])
  }

  get canGetAnalysis () : boolean {
    return this.authService.hasPermission([Permission.GetAnalysis])
  }

  isUnsupportedProduct (productId: string) : boolean {
    const serviceId = this.proposition.locationInquiry.booking.serviceId ?? ''
    const serviceProduct = this.serviceProducts.find(serviceProduct => serviceProduct.productId === productId && serviceProduct.serviceId === serviceId)
    return !serviceProduct?.isBookable
  }

  hasUnsupportedProducts () : boolean {
    if (!this.usesFlexkidsProducts()) {
      return false
    }

    for (const reservation of this.proposition.placement.reservations) {
      const product = reservation.product
      if (product && this.isUnsupportedProduct(product.id)) {
        return true
      }
    }
    return false
  }

  get canPropose () : boolean {
    return !this.hasUnsupportedProducts()
  }

  usesFlexkidsProducts () : boolean {
    return this.proposition.placement?.reservations?.some(reservation => reservation.product != null) ?? false
  }

  getProducts () : Product[] {
    const reservationsProducts = this.currentProposition.placement?.reservations
      ?.filter(reservation => reservation.product !== null && reservation.product !== undefined)
      .map(reservation => reservation.product as Product) ?? []

    if (reservationsProducts.length === 0) {
      return []
    }

    const distinctProductsMap = new Map()
    reservationsProducts.forEach(product => {
      distinctProductsMap.set(product.id, product)
    })

    const distinctProducts = Array.from(distinctProductsMap.values())
    return distinctProducts
  }

  async getConfigurationTableDataAsync (): Promise<void> {
    const configurationService = container.get<IConfigurationService>(SERVICE_IDENTIFIERS.IConfigurationService)
    const cancelResult: PlannerCausedByReason[] = await configurationService.getConfigurationByKeyAsync({ key: 'planner_proposition_cancel_reasons', date: new Date() })
    this.cancelReasons = cancelResult

    const revokeResult: PlannerCausedByReason[] = await configurationService.getConfigurationByKeyAsync({ key: 'planner_proposition_revoke_reasons', date: new Date() })
    this.revokeReasons = revokeResult
  }

  onProposeClicked () : void {
    this.causedByReasonType = ''
    this.plannerActionError = false
    this.showProposeDialog = true
  }

  onCancelClicked () : void {
    this.causedByReasonType = ''
    this.plannerActionError = false
    this.showCancelDialog = true
  }

  onRevokeClicked () : void {
    this.causedByReasonType = ''
    this.plannerActionError = false
    this.showRevokeDialog = true
  }

  onExtendClicked () : void {
    this.extendedDate = null
    this.plannerActionError = false
    this.showExtendDialog = true
  }

  async OnWaitinglistPropositionProposedAsync () : Promise<void> {
    const updatedPropositionResult = await this.waitingListPropositionService.proposeCreatedWaitingListProposition({ waitingListPropositionId: this.currentProposition.id })
    const handleResult = await this.handleUpdatedProposition(updatedPropositionResult)
    this.showProposeDialog = !handleResult
  }

  async OnWaitinglistPropositionCancelledAsync () : Promise<void> {
    if (!this.invalidCausedByReason()) {
      const updatedPropositionResult = await this.waitingListPropositionService.cancelCreatedWaitingListProposition({
        waitingListPropositionId: this.currentProposition.id,
        causedByReasonType: this.causedByReasonType,
        causedByReasonComment: this.causedByReasonComment.trim() || null
      })
      const handleResult = await this.handleUpdatedProposition(updatedPropositionResult)
      this.showCancelDialog = !handleResult
    }
  }

  async OnWaitinglistPropositionRevokedAsync () : Promise<void> {
    if (!this.invalidCausedByReason()) {
      const updatedPropositionResult = await this.waitingListPropositionService.revokeProposedWaitingListProposition({
        waitingListPropositionId: this.currentProposition.id,
        causedByReasonType: this.causedByReasonType,
        causedByReasonComment: this.causedByReasonComment.trim() || null
      })
      const handleResult = await this.handleUpdatedProposition(updatedPropositionResult)
      this.showRevokeDialog = !handleResult
    }
  }

  async OnWaitinglistPropositionExtendedAsync () : Promise<void> {
    if (this.extendedDate != null) {
      const updatedPropositionResult = await this.waitingListPropositionService.extendProposedWaitingListPropositionAsync(this.currentProposition.id, this.extendedDate)
      const handleResult = await this.handleUpdatedProposition(updatedPropositionResult)
      this.showExtendDialog = !handleResult
    }
  }

  invalidCausedByReason () : boolean {
    return this.causedByReasonType === '' || this.causedByReasonComment.trim().length > this.commentMaxLength
  }

  onPropositionActionCancelled () : void {
    this.showProposeDialog = false
    this.showCancelDialog = false
    this.showRevokeDialog = false
    this.showExtendDialog = false
  }

  onCausedByReasonTypeChanged (reasonType: string) : void {
    this.causedByReasonType = reasonType
  }

  onCausedByReasonCommentChanged (reasonComment: string) : void {
    this.causedByReasonComment = reasonComment
  }

  onExtendedDateChanged (extendedDate: Date) : void {
    this.extendedDate = extendedDate
  }

  async handleUpdatedProposition (updatedPropositionResult: WaitingListPropositionResponse) : Promise<boolean> {
    if (!updatedPropositionResult || !updatedPropositionResult.isSuccess) {
      this.plannerActionError = true
      return false
    }

    if (this.currentProposition) {
      this.plannerActionError = false
      const updatedProposition = await this.propositionService.getOnePropositionAsync(this.currentProposition.id)
      // Overwrite currentProposition values with new values to update the expanded proposition item
      this.currentProposition.status = updatedProposition.status ?? ''
      this.currentProposition.causedBy = updatedProposition.causedBy
      this.currentProposition.causedByReasonType = updatedProposition.causedByReasonType
      this.currentProposition.causedByReasonComment = updatedProposition.causedByReasonComment
      this.currentProposition.expiresOn = updatedProposition.expiresOn
      this.currentProposition.proposedOn = updatedProposition.proposedOn
      this.currentProposition.placement.reservations = updatedProposition.placement?.reservations ?? []
      this.currentProposition.placement.status = updatedProposition.placement?.status ?? ''
      this.onPropositionChanged(this.currentProposition as WaitingListProposition)
    }
    return true
  }

  @Emit('onPropositionChanged')
  onPropositionChanged (proposition: WaitingListProposition) : WaitingListProposition {
    return proposition
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/helpers.scss';

.row-data {
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
}

.flex-row-space-between {
  justify-content: space-between;
  gap: 16px;
}

.d-flex-full-width {
  display: flex;
  width: 100%;
}

.cancel-reason-data {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
}

.action-buttons {
  margin-left: auto;
}

::v-deep .data-component {
  margin-bottom: 24px;
    p {
    color: $partou-primary-black-eighty;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &:last-child {
      margin: 0px !important;
    }
  }
}

::v-deep .data-labels {
  color: $partou-primary-black-seventy;
  border-bottom: 1px solid $partou-primary-salmon-fourty;
  width: fit-content;
  padding-bottom: 4px;
}

.max-height-45{
  max-height: 45px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $partou-primary-warm-grey-sixty;
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>
<style lang="scss">
@import '@/styles/variables/variables.scss';
@import '@/styles/helpers.scss';
.revoke-reason-options {
  .vs--searchable {
    .vs__dropdown-menu {
      .vs__dropdown-option {
        white-space: initial !important;
        overflow: unset !important;
        text-overflow: unset !important;
        height: unset !important;
      }
    }
  }
}
</style>
