<template>
  <div class="service-product-add-table">
    <div>
      <div class="d-flex pt-4 pb-4">
        <div class="category-picker">
          <span class="autocomplete-label">{{$t('services.products.table.filters.categoryLabel')}}</span>
          <PartouAutocomplete
            class="rounded-xl borderless"
            v-model="tableOptions.filters.category"
            item-text="name"
            item-value="value"
            label="name"
            :disabled="isLoading"
            :items="categoryOptions"
            :appendIconString="'$vuetify.icons.partouChevronDown'"
            :clearable="true"
            :placeholder="$t('services.products.table.filters.categoryPlaceholder')"
            :noDataText="$t('services.products.table.filters.noData')"
            @input="onSearchChanged" />
        </div>
        <div v-if="displayServiceVarietyFilter" class="service-variety-picker ml-4">
          <span class="autocomplete-label">{{$t('services.products.table.filters.serviceVarietyLabel')}}</span>
          <PartouAutocomplete
            class="rounded-xl borderless"
            v-model="tableOptions.filters.serviceVariety"
            item-text="name"
            item-value="name"
            label="name"
            :disabled="isLoading"
            :items="serviceVarietyOptions"
            :appendIconString="'$vuetify.icons.partouChevronDown'"
            :clearable="true"
            :placeholder="$t('services.products.table.filters.serviceVarietyPlaceholder')"
            :noDataText="$t('services.products.table.filters.noData')"
            @input="onSearchChanged" />
        </div>
        <div class="service-variety-picker ml-4">
          <span class="autocomplete-label">{{ $t('services.products.table.filters.searchLabel') }}</span>
          <PartouTextField class="rounded-xl borderless" v-model="tableOptions.filters.global" size="small" :label="$t('table.searchLabel')"  :iconName="'$vuetify.icons.partouSearch'" :iconPosition="'right'" @input="onSearchChanged" :isClearable="true" :disabled="isLoading"></PartouTextField>
        </div>
      </div>
    </div>
    <PartouDataTable
      class="service-product-add-table"
      ref="partou-data-table"
      :headers="headers"
      :items="items"
      :totalCount="totalCount"
      :showExpand="false"
      :tableOptions="tableOptions"
      :isLoading="isLoading"
      :fixedHeader="true"
      :itemClass="getRowBackground"
      @onOptionsChanged="onTableOptionsChangedAsync">
      <!-- custom column templates -->
      <template #item.isEnabled="{ item }"> <!-- eslint-disable-line -->
          <PartouCheckBox :value="isSelectedProduct(item.productId)" :disabled="checkIfDisabledItem(item)" @click="updateSelectedProduct(item.productId)"/>
      </template>
      <template #item.name="{ item }"> <!-- eslint-disable-line -->
        {{ item.product.name }}
      </template>
      <template #item.category="{ item }"> <!-- eslint-disable-line -->
        {{ $t(`services.products.table.category.${item.product.category}`)  }}
      </template>
      <template #item.serviceVariety="{ item }"> <!-- eslint-disable-line -->
        {{ item.product.serviceVariety }}
      </template>
      <template #item.hourlyRate="{ item }"> <!-- eslint-disable-line -->
        {{ getHourlyRate(item) }}
      </template>
      <template #item.validFrom="{ item }"> <!-- eslint-disable-line -->
        {{ item.validFrom | formatDate}}
      </template>
      <template #item.canBeActivated="{ item }"> <!-- eslint-disable-line -->
        <v-tooltip top v-if="hasDuplicateTimes(item) && item.isBookable">
          <template v-slot:activator="{ on, attrs }">
            <v-icon ref="unkown-subscription-icon" v-bind="attrs" v-on="on" class="exclamation-mark-circled">$vuetify.icons.partouExclamationMarkCircledThinWarning</v-icon>
          </template>
          <p class="text-center white--text mt-4">{{ $t('services.products.table.notUniqueProductTime') }}</p>
        </v-tooltip>
        <v-tooltip top v-if="!item.isBookable">
          <template v-slot:activator="{ on, attrs }">
            <v-icon ref="unkown-subscription-icon" v-bind="attrs" v-on="on" class="exclamation-mark-circled">$vuetify.icons.values.partouExclamationMarkCircledThinError</v-icon>
          </template>
          <p class="text-center white--text mt-4">{{ $t(`services.products.table.notBookableProduct${item.isBookableReason}`) }}</p>
        </v-tooltip>
      </template>
    </PartouDataTable>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Ref } from 'vue-property-decorator'
import { DataTableHeader } from '@/components/PartouComponents/PartouDataTable'
import PartouDataTable from '@/components/PartouComponents/PartouDataTable/PartouDataTable.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import BaseEntityTable from '@/pages/_base/BaseEntityTable'
import PartouTextField from '@/components/PartouComponents/PartouTextField.vue'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import PartouCheckBox from '@/components/PartouComponents/PartouCheckBox/PartouCheckBox.vue'
import ServiceProductTableOptions from '@/pages/ServicesPage/ServiceProduct/ServiceProductTable/ServiceProductTableOptions'
import { IProductService } from '@/services/ProductService/IProductService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import { ServiceProduct, ServiceVarietyName, ProductPriceAgreement } from '@/models'
import { CategoryOption } from '@/pages/ServicesPage/ServiceProduct/CategoryOptions'

@Component({
  components: { PartouCard, PartouDataTable, DaysCoverage, PartouTextField, PartouCheckBox, PartouAutocomplete }
})
export default class AddProductsToServiceTable extends BaseEntityTable<ServiceProduct> {
  productService: IProductService = container.get<IProductService>(SERVICE_IDENTIFIERS.IProductService)
  serviceService: IServiceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)

  @Prop({ required: true })
  serviceId!: string

  @Prop({ required: true })
  items!: Partial<ServiceProduct>[]

  @Prop({ required: true, default: [] })
  activatedProducts!: Partial<ServiceProduct>[]

  @Ref('partou-data-table')
  partouDataTable!: PartouDataTable<ServiceProduct>

  @Prop({ required: true })
  selectedProductsToAdd!: Set<string>

  @Prop({ required: true })
  categoryOptions!: CategoryOption[]

  @Prop({ required: true })
  serviceVarietyOptions!: string[]

  @Prop({ required: true, default: false })
  displayServiceVarietyFilter!: boolean

  headers: DataTableHeader[] = [
    { text: '', value: 'isEnabled', width: '3%', sortable: false },
    { text: 'services.products.table.columns.name', sortBy: 'product.name', value: 'name', width: '36%' },
    { text: 'services.products.table.columns.category', sortBy: 'product.category', value: 'category', width: '15%' },
    { text: 'services.products.table.columns.serviceVariety', sortBy: 'product.serviceVariety', value: 'serviceVariety', width: '15%' },
    { text: 'services.products.table.columns.hourlyRate', value: 'hourlyRate', width: '15%', sortable: false },
    { text: 'services.products.table.columns.vaildFrom', value: 'validFrom', width: '15%', sortable: false },
    { text: '', sortable: false, value: 'canBeActivated', width: '1%' }
  ]

  activatedAndSelectedProducts: Partial<ServiceProduct>[] = []

  tableOptions : ServiceProductTableOptions = {
    sortBy: ['product.name', 'product.category', 'product.serviceVariety'],
    sortDesc: [false, false, false],
    page: 1,
    itemsPerPage: 5,
    itemsPerPageOptions: [5, 10, 25, 50, 100],
    filters: {}
  }

  mounted () : void {
    this.onTableOptionsChangedAsync(this.tableOptions)
    this.activatedAndSelectedProducts = this.activatedProducts
  }

  onSearchChanged () : void {
    this.partouDataTable.onFilterChanged()
  }

  isSelectedProduct (item: string): boolean {
    if (this.selectedProductsToAdd.has(item)) {
      return true
    } else {
      return false
    }
  }

  @Emit('OnSelectedProductsChanged')
  updateSelectedProduct (item: string) {
    if (this.selectedProductsToAdd.has(item)) {
      this.selectedProductsToAdd.delete(item)
      this.activatedAndSelectedProducts = this.activatedAndSelectedProducts.filter(e => e.product?.id !== item)
    } else {
      this.selectedProductsToAdd.add(item)
      const p = this.items.find(e => e.product?.id === item)
      if (p !== undefined) {
        this.activatedAndSelectedProducts.push(p)
      }
    }
  }

  getHourlyRate (serviceProduct: ServiceProduct) : string {
    const productPriceAgreement: Partial<ProductPriceAgreement> = serviceProduct.productPriceAgreement?.at(0) ?? {}
    const amountsArray: number[] = Object.values(productPriceAgreement)
      .filter((amount): amount is number => amount !== undefined && amount !== 0)

    const price = amountsArray[0] ?? 0
    return `€ ${(price / 100).toFixed(2).replace('.', ',')}`
  }

  hasDuplicateTimes (item : Partial<ServiceProduct>) : boolean {
    const filteredList = this.activatedAndSelectedProducts.filter(e => e.product?.serviceVariety === item.product?.serviceVariety && e.product?.category === item.product?.category && item.product?.id !== e.product?.id)
    switch (item.product?.serviceVariety) {
    case ServiceVarietyName.KDV:
      return filteredList.some(e => e.product?.productDayInfos[0].startTime === item.product?.productDayInfos[0].startTime && e.product?.productDayInfos[0].endTime === item.product?.productDayInfos[0].endTime)
    case ServiceVarietyName.NSO:
      return filteredList.some(e => e.product?.productDayInfos[0].endTime === item.product?.productDayInfos[0].endTime)
    case ServiceVarietyName.VSO:
      return filteredList.some(e => e.product?.productDayInfos[0].startTime === item.product?.productDayInfos[0].startTime)
    default:
      return true
    }
  }

  checkIfDisabledItem (item : Partial<ServiceProduct>) : boolean {
    return !item.isBookable || this.hasDuplicateTimes(item)
  }

  getRowBackground (item : Partial<ServiceProduct>) : string {
    return this.checkIfDisabledItem(item) ? 'disabled-row-opacity' : ''
  }
}
</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';

.service-product-add-table > .v-data-table > .v-data-table__wrapper{
   max-height: 40vh !important;
}

</style>
<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
.exclamation-mark-circled {
    width: 20px;
    height: 20px;
  }

h2 {
    margin-bottom: 16px;
  }
  .search {
    max-width: 300px !important;
    width: 300px !important;
    margin-top: 26px !important;
  }

  .service-product-table {
    width: 100%;
  }

  .new-status {
    font-weight: bold;
    color: $partou-primary-orange;
  }

  .no-border {
    border: unset;
  }

.category-picker, .service-variety-picker {
  min-width: 300px;
}

.category-picker.background-grey,
.service-variety-picker.background-grey {
  .vs__selected-options {
  background: $partou-background !important;
  }
  .vs__dropdown-menu {
    background: $partou-background !important;
  }
}

.category-picker .vs__selected-options,
.service-variety-picker .vs__selected-options {
  height: 40px;

  .vs__search {
    font-weight: bold;
  }
  .vs__selected {
    display:flex;
    font-weight: bold;
    align-items: center;
    color: $partou-primary-black-ninety  !important;
  }
  .vs__search::placeholder {
    color: $partou-primary-black-fourty !important;
    font-weight: normal;
  }
}
</style>
