<template>
  <div class="d-flex flex-row mt-4 mb-6 ml-4">
        <v-switch inset :disabled="!canManageProducts" ref="flexkids-products-switch" class="mt-0 mb-0 pt-0"  v-model="serviceProduct.showTextualAddition" @click="onShowTextualAdditionClickedAsync()" />
    <div class="product-textual-addition-data">
      <div class="d-flex">
          <p class="data-labels">{{ $t('services.products.table.expanded.textualAddition')}}</p>
          <v-btn icon v-if="canManageProducts" class="edit-button" :ripple="false" @click="onEditClicked()">
            <v-icon class='edit-icon'>$vuetify.icons.partouEditNew</v-icon>
          </v-btn>
      </div>
      <p class="mb-8"><b>{{ serviceProduct.textualAddition }}</b></p>
    </div>
    <div class="name-data"></div>
    <div class="hourly-rate-data">
      <v-btn v-if="canManageProducts" class="hidden-sm-and-down deactivate-product" rounded :ripple="false" @click="onDeactivateProductClickedAsync()">{{$t('services.products.table.expanded.deactivateButton')}}</v-btn>
    </div>
    <div class="expand-data"></div>
    <PartouDialog
      v-if="showEditProductDialog"
      ref="edit-product-dialog"
      :width="600"
      :showDialog="showEditProductDialog"
      :showLoaderOnConfirm="true"
      :onNotifyAcceptedAsync="onEditSavedAsync"
      :isConfirmButtonDisabled="invalidTextualAddition()"
      :cancelTranslationString="$t('services.products.table.expanded.edit.cancel')"
      :confirmTranslationString="$t('services.products.table.expanded.edit.confirm')"
      @canceled="onEditCancelled">
        <p v-html="$t('services.products.table.expanded.edit.dialogTitle', { service: serviceName})"></p>
        <p class="textual-addition-label">{{ $t('services.products.table.expanded.edit.textualAdditionLabel') }}</p>
        <v-textarea
          class="textual-addition-field mb-6"
          v-model="newTextualAddition"
          solo
          flat
          :rules="[v => validateTextualAddition(v)]"
          :aria-label="$t('services.products.table.expanded.edit.textualAdditionLabel')"
          :placeholder="$t('services.products.table.expanded.edit.textualAdditionPlaceholder')"
        />
        <div class="button-error-container">
          <span v-if="plannerActionError" class="error-message">{{$t('services.controlVariables.triggerCalculateCapacityError')}}</span>
        </div>
    </PartouDialog>

    <PartouDialog
      :width="600"
      v-if="showConfirmRemoveCalculationDialog"
      :showDialog="showConfirmRemoveCalculationDialog"
      @accepted="onShowConfirmRemoveCalculationProductDialogConfirmedAsync"
      @canceled="onShowConfirmRemoveCalculationProductDialogCanceled"
      :cancelTranslationString="'services.products.table.expanded.removeCalculationProduct.cancel'"
      :confirmTranslationString="'services.products.table.expanded.removeCalculationProduct.remove'">
        <div class="d-flex flex-row dialog-header">
          <v-icon class="exclamation-mark-circled">$vuetify.icons.partouExclamationMarkCircledThinError</v-icon>
          <h2 v-html="$t('services.products.table.expanded.removeCalculationProduct.title')"></h2>
        </div>
        <p class="mb-6 mt-6" v-html="$t('services.products.table.expanded.removeCalculationProduct.description')"></p>
    </PartouDialog>

    <PartouDialog
      :width="600"
      v-if="showConfirmRemoveProductDialog"
      :showDialog="showConfirmRemoveProductDialog"
      @accepted="onShowConfirmRemoveProductDialogConfirmedAsync"
      @canceled="onShowConfirmRemoveProductDialogCanceled"
      :confirmTranslationString="$t('services.products.table.expanded.removeProduct.remove')"
      :cancelTranslationString="$t('services.products.table.expanded.removeProduct.cancel')">
        <h3>{{$t('services.products.table.expanded.removeProduct.title')}}</h3>
        <p class="mb-6 mt-6" v-html="$t(serviceProduct.isUsedInChildBenefitCalculator ?
          'services.products.table.expanded.removeProduct.descriptionWithCalculationProduct' :
          'services.products.table.expanded.removeProduct.description',
        { serviceVariety: serviceVarietyTranslationForPopup})"></p>
    </PartouDialog>

  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import Permission from '@/models/enums/Permission'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IAuthService } from '@/services/AuthService/IAuthService'
import { ProductCategory, ServiceProduct, ServiceVarietyName } from '@/models'
import { IProductService } from '@/services/ProductService/IProductService'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import { IServiceSettingsService } from '@/services/ServiceSettingsService/IServiceSettingsService'
import { IServiceService } from '@/services/ServiceService/IServiceService'

@Component({
  components: { PartouDialog }
})
export default class ServiceProductTableExpanded extends Vue {
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)
  productService: IProductService = container.get<IProductService>(SERVICE_IDENTIFIERS.IProductService)
  serviceService: IServiceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)
  serviceSettingsService: IServiceSettingsService = container.get<IServiceSettingsService>(SERVICE_IDENTIFIERS.IServiceSettingsService)

  @Prop({ required: true })
  serviceProduct!: ServiceProduct

  @Prop({ required: true })
  serviceId!: string

  @Prop({ required: true })
  serviceName!: string

  @Prop({ required: true })
  serviceAvailableInCalculator!: boolean

  @Prop({ required: true })
  serviceIsBookableInSelectionGuide!: boolean

  @Prop({ required: true })
  useFlexkidsProducts!: boolean

  @Prop({ required: true, default: [] })
  activatedProducts!: Partial<ServiceProduct>[]

  showEditProductDialog = false
  plannerActionError = false
  newTextualAddition = ''

  showConfirmRemoveProductDialog = false
  showConfirmRemoveCalculationDialog = false

  get canManageProducts () : boolean {
    return this.authService.hasPermission([Permission.ManageProduct])
  }

  async onShowTextualAdditionClickedAsync () : Promise<void> {
    await this.productService.UpdateServiceProductShowTextualAdditionAsync(this.serviceId, this.serviceProduct.productId, this.serviceProduct.showTextualAddition)
  }

  get serviceVarietyTranslationForPopup () : string {
    return this.serviceProduct.product?.serviceVariety === ServiceVarietyName.KDV.toString() ? '' : this.$t('services.products.table.expanded.removeProduct.NSO').toString()
  }

  onEditClicked () : void {
    this.newTextualAddition = this.serviceProduct.textualAddition
    this.showEditProductDialog = true
  }

  async onEditSavedAsync () : Promise<void> {
    this.plannerActionError = false
    const productId = this.serviceProduct?.product?.id ?? ''
    if (this.newTextualAddition !== this.serviceProduct.textualAddition) {
      await this.productService.UpdateServiceProductTextualAdditionAsync(this.serviceId, productId, this.newTextualAddition)
    }
    this.showEditProductDialog = false
    this.onExpandedChanged([this.serviceProduct])
  }

  onEditCancelled () : void {
    this.showEditProductDialog = false
    this.plannerActionError = false
  }

  async onShowConfirmRemoveProductDialogConfirmedAsync () : Promise<void> {
    this.showConfirmRemoveProductDialog = false
    if (this.useFlexkidsProducts) {
      // This needs to be done first otherwise it doesn't work, don't ask me why
      this.emitOnDisableShowServiceInChildBenefitCalculator()
    }

    await this.deactivateProductAsync()

    if (this.useFlexkidsProducts) {
      await this.serviceSettingsService.setBookableInSelectionGuideAsync(this.serviceId, false)
      await this.serviceSettingsService.setAllowDirectPlacementAsync(this.serviceId, false)
      await this.serviceService.disableWaitingListAutomationAsync(this.serviceId)
      await this.serviceSettingsService.setAvailableInCalculatorAsync(this.serviceId, false)
    }
    if (this.serviceProduct.isUsedInChildBenefitCalculator) {
      await this.productService.DeactivateIsUsedInChildBenefitCalculatorForService(this.serviceId, this.serviceProduct.product?.id)
    }
  }

  onShowConfirmRemoveProductDialogCanceled () : void {
    this.showConfirmRemoveProductDialog = false
  }

  async onShowConfirmRemoveCalculationProductDialogConfirmedAsync () : Promise<void> {
    this.showConfirmRemoveCalculationDialog = false

    if (this.useFlexkidsProducts) {
      // This needs to be done first otherwise it doesn't work, don't ask me why
      this.emitOnDisableShowServiceInChildBenefitCalculator()
    }

    await this.deactivateProductAsync()

    if (this.useFlexkidsProducts) {
      await this.productService.DeactivateIsUsedInChildBenefitCalculatorForService(this.serviceId, this.serviceProduct.product?.id)
      await this.serviceSettingsService.setAvailableInCalculatorAsync(this.serviceId, false)
    }
  }

  @Emit('onDisableShowServiceInChildBenefitCalculator')
  emitOnDisableShowServiceInChildBenefitCalculator () : void {
    // Used to emit the onDisableShowServiceInChildBenefitCalculator event
  }

  onShowConfirmRemoveCalculationProductDialogCanceled () : void {
    this.showConfirmRemoveCalculationDialog = false
  }

  async onDeactivateProductClickedAsync () : Promise<void> {
    const currentDate = new Date()

    // The product that's being deactivated is either VSO and not used in the benefits calculator, school weeks, or not
    // yet active, so can be deactivated.
    const isVSOProduct = this.serviceProduct.product?.serviceVariety === ServiceVarietyName.VSO
    const canDeactivate =
      !this.useFlexkidsProducts ||
      (isVSOProduct && !this.serviceProduct.isUsedInChildBenefitCalculator) ||
      this.serviceProduct.product?.category === ProductCategory.SchoolWeeks ||
      new Date(this.serviceProduct.validFrom) > currentDate

    // Product is VSO, school weeks, or not yet active so it can be deactivated.
    if (canDeactivate) {
      await this.deactivateProductAsync()
      return
    }

    // Number of products left that are eligible to be used for the benefits calculator.
    const numCalculatorEligibleProducts = this.activatedProducts.filter(
      e => e.product?.serviceVariety !== ServiceVarietyName.VSO &&
      e.product?.category === ProductCategory.AllWeeks &&
      new Date(e.validFrom) <= currentDate
    ).length

    if (this.serviceIsBookableInSelectionGuide || this.serviceAvailableInCalculator) {
      // Show a warning when the user is about to deactivate the last bookable product that's not VSO.
      if (!isVSOProduct && numCalculatorEligibleProducts === 1) {
        this.showConfirmRemoveProductDialog = true
        return

      // Show a warning when the user is about to deactivate a product that's currently used in the benefits calculator,
      // but there are still other products left that can be configured for the calculator.
      } else if (this.serviceProduct.isUsedInChildBenefitCalculator) {
        this.showConfirmRemoveCalculationDialog = true
        return
      }
    }

    // Product is not used in benefits calculator, or the last bookable product and thus it can be deactivated.
    await this.deactivateProductAsync()
  }

  async deactivateProductAsync () : Promise<void> {
    const productId = this.serviceProduct?.product?.id ?? ''
    await this.productService.DeactivateServiceProductAsync(this.serviceId, productId)
    await this.productService.DeactivateIsUsedInChildBenefitCalculatorForService(this.serviceId, productId)
    this.onProductDeactivated()
    this.onExpandedChanged([this.serviceProduct])
  }

  @Emit('onProductDeactivated')
  onProductDeactivated () : void {
    // Used to emit the onProductDeactivated event
  }

  @Emit('onExpandedChanged')
  onExpandedChanged (items: Partial<ServiceProduct>[]) : Partial<ServiceProduct>[] {
    return items
  }

  invalidTextualAddition () : boolean {
    if (this.newTextualAddition.length > 300) {
      return true
    }
    return false
  }

  validateTextualAddition (value: string) : string | boolean {
    if (value.length > 300) {
      return 'Maximaal 300 tekens'
    }
    return true
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/helpers.scss';
@import '@/styles/switch.scss';

.font-large {
  font-size: 16px;
  margin-bottom: 32px;
}
.child-information {
  margin-top: 4px;
  font-size: 16px;
}

.propose-button {
  color: $partou-primary-white;
  background-color: $partou-primary-salmon !important;
  text-transform: none !important;
}
.data-labels {
  font-size: 13px;
  color: $partou-primary-black-fifty;
  margin-bottom: 13px;
}

.name-data {
  width: 30%;
  padding: 0 16px;
}
.product-textual-addition-data {
  width: 48%;
  padding: 0 12px;
}
.service-variety-data {
  width: 0%;
}
.hourly-rate-data {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 19%;
  padding: 0 16px;
}
.expand-data {
  width: 3%;
}

.deactivate-product {
  color: $partou-primary-white;
  background: $partou-primary-gradient !important;
  box-shadow: none;
  text-transform: none !important;
}

.edit-icon {
  width: 16px;
  height: 16px;
  margin-top: 0;
  fill: $partou-primary-salmon !important;
}

.textual-addition-label {
  font-size: 13px;
  color: $partou-primary-black-fifty;
}

.textual-addition-field {
  padding: 3px;
  border-radius: 24px;
  border: 1px solid $partou-primary-black-sixty;
}

.edit-button {
  height: unset;
  width: unset;
  padding-left: 12px;
  margin-top: -8px;
}

.edit-button.v-btn::before {
  background: none !important;
}

.dialog-header {
  align-items: center;
  gap: 8px;

  h2 {
    color: $partou-primary-salmon !important;
    margin: 0;
  }
}
</style>
